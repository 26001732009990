import React from "react";
import { SustainabilityComponent } from "./suataiability";
import { SustainabilityComponentTwo } from "./sustainabilitytwo";
import { SustainabilityComponentThree } from "./sustainabilitythree";
import { SustainabilityComponentFour } from "./sustainabilityfur";


export const Sustainabilitys = () => {
  return (
    <div>
     <SustainabilityComponent/>
     <SustainabilityComponentTwo/>
     <SustainabilityComponentThree/>
     <SustainabilityComponentFour/>
    </div>
  );
};