import React from 'react';
import sunknitLogo from '../../Component Library/svg/sunKnitLogo.svg';
import fblogo from '../../Component Library/fblogo.svg'
import linkedinlogo from '../../Component Library/linkedinlogo.svg'
import youtubelogo from '../../Component Library/svg/youtubelogo.svg'
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

// Functional Component
const Footer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Rendering Logic
  return (
    <div>
      <div className='flex w-screen h-[692px] bg-black flex-col items-center justify-center'>
        <div className='flex-row flex h-[60%] w-full items-center justify-center'>
          <div className='w-[30%] h-full flex flex-col 2xl:ml-[144px] 2xl:ml-[144px] md:ml-[124px] mt-[197px]'>
            <Link to="/"><img src={sunknitLogo}  className='mb-[42px] 2xl:w-[235px] md:h-[58px] md:w-[175px] md:h-[58px]' /></Link>
            <div className='leading-[2.2rem]'>
              <p className='text-white text-left 2xl:text-[18px] md:text-[14px] font-poppins font-light'>S.F.NO: 250/1,</p>
              <p className='text-white text-left font-poppins 2xl:text-[18px] md:text-[14px] font-normal font-light'>Sri Kanchi Kamatchi Nagar,</p>
              <p className='text-white text-left font-poppins 2xl:text-[18px] md:text-[14px] font-normal font-light'>Kangayampalayam Pudur,</p>
              <p className='text-white text-left font-poppins 2xl:text-[18px] md:text-[14px] font-normal font-light'>Kangayam Road,Tirupur - 641604</p>
              <p className='text-white 2xl:text-[18px] md:text-[14px] font-normal font-light'></p>
              </div>
          </div>

          <div className='w-[1px] h-[50%] bg-[#5A5A5A]'></div>
          <div className='w-[60%] h-full flex items-center justify-start 2xl:ml-[122px] md:ml-[82px] text-white gap-[40px]'>
            <div className='font-normal text-base font-poppins text-[19px]'>
              <Link to="/">
                Home
              </Link>
            </div>
            <div className='font-normal text-base font-poppins text-[19px]'>
              <Link to="/about">About us</Link>

            </div>
            <div className='font-normal text-base font-poppins text-[19px]'>
              <Link to="/infrastructure">Infrastructure</Link>

            </div>
            <div className='font-normal text-base font-poppins text-[19px]'>
              <Link to="/products">
                Products
              </Link>
            </div>
            <div className='font-normal text-base font-poppins text-[19px]'>
              <Link to="/sustainability">
                Sustainability</Link>
            </div>
            <div className='font-normal text-base text-[19px] font-poppins'>
              <Link to="/contact">
                Contact us</Link>
            </div>
          </div>
        </div>
        {/* <div className='h-[1px] w-[70%] bg-[#ffff] mt-[12px]'></div> */}
        <div className='h-[1px] w-[70%] bg-[#5A5A5A] '></div>
        <div className='flex flex-col w-full h-[40%] items-center justify-center text-white gap-[20px]'>
          <div className='text-[21px] font-medium'>Follow Us</div>
          <div className='flex flex-row gap-[25px] items-center justify-center'>
            <img src={fblogo}></img>
            <img src={linkedinlogo}></img>
            <img src={youtubelogo}></img>
          </div>
          <div className='text-[21px] font-medium text-white '>© 2024,Sunknit Fashions . All Rights Reserved</div>

        </div>
      </div>
    </div>
  );
};

export default Footer;