import React from "react";
import { useState } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import images1 from '../../Component Library/swiperslider1.png'
import images2 from '../../Component Library/swiperslider2.jpg'
import images3 from '../../Component Library/fire.jpg'
import images4 from '../../Component Library/swiperslider4.jpg'
import infrarrowright from '../../Component Library/svg/right circle.svg'
import infrarrowleft from '../../Component Library/svg/left circle.svg'
import infrarrowrightmobile from '../../Component Library/Button Right.svg'
import infrarrowleftmobile from '../../Component Library/Button Left.svg'
const ImageSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slides = [
        { image: images1, title: "Organised Production Lines" },
        { image: images2, title: "Dedicated IT Team " },
        { image: images4, title: "Meeting / Display Hall " },
        { image: images3, title: "Safety Protocol Check " },
    ];


    
    return (
        
        <div className="flex justify-center items-center mt-[74px]">
                            {/* desktop Slides */}
            <div className="max-w-2xl relative hidden md:block lg:block mt-[32px]">
                {/* Slides */}
                {slides.map((slide, index) => (
                    <div
                        className={`${index === currentSlide ? "block" : "hidden"
                            } h-80  relative`}
                        key={index}
                    >
                        <img className="w-[100%] 2xl:h-[321px] md:h-[244px]  rounded-[35px]" src={slide.image} alt={`Slide ${index + 1}`} />
                    </div>
                ))}

                {/* Title outside of the image */}
                <div className="absolute top-[-54px] left-0 right-0 flex items-center justify-center mt[-34px]">
                    <h2 className="text-black 2xl:text-[35px] md:text-[25px] font-bold font-causten font-bold ">
                        {slides[currentSlide].title}
                    </h2>
                </div>

                {/* Left Arrow  FFC79B*/}
                <div className="flex items-center justify-center mr-[74px]">
                    <div
                        className={`absolute top 2xl:mt-[119px] md:mt-[-79px] transform -translate-y-1/4 ml-[144px] cursor-pointer`}
                        onClick={() =>
                            setCurrentSlide(
                                currentSlide === 0 ? slides.length - 1 : currentSlide - 1
                            )
                        }
                    >
        <img src={infrarrowright} className="2xl:w-[72%] md:w-[42%] ml-[17px]" alt="arrow" />
                    </div>

                    {/* Right Arrow */}
                    <div
                        className={`absolute top 2xl:mt-[119px] md:mt-[-79px]  transform -translate-y-1/4  cursor-pointer`}
                        onClick={() =>
                            setCurrentSlide(
                                currentSlide === slides.length - 1 ? 0 : currentSlide + 1
                            )
                        }
                    >
                   <img src={infrarrowleft} className="2xl:w-[72%] md:w-[42%] mr-[17px]" alt="arrow" />
                     </div>
                </div>
            </div>

          {/* desktop Slides */}
           


 {/* Mobile Slides */}
 <div className="max-w-2xl relative xl:hidden sm:block  mt-[32px]">
                {/* Slides */}
                {slides.map((slide, index) => (
                    <div
                        className={`${index === currentSlide ? "block" : "hidden"
                            } h-80  relative`}
                        key={index}
                    >
                        <img className="w-[100%] h-[221px] rounded-[35px]" src={slide.image} alt={`Slide ${index + 1}`} />
                    </div>
                ))}

                {/* Title outside of the image */}
                <div className="absolute top-[-54px] left-0 right-0 flex items-center justify-center mt[-34px]">
                    <h2 className="text-black xl:text-[15px] sm:text-[20px] font-bold font-causten  ">
                        {slides[currentSlide].title}
                    </h2>
                </div>

                {/* Left Arrow  FFC79B*/}
                <div className="flex items-center justify-center mr-[74px]">
                    <div
                        className={`absolute top mt-[-125px] pr-[22px] transform -translate-y-1/4 ml-[144px] cursor-pointer`}
                        onClick={() =>
                            setCurrentSlide(
                                currentSlide === 0 ? slides.length - 1 : currentSlide - 1
                            )
                        }
                    >
        <img src={infrarrowrightmobile} className="w-[72%] ml-[17px]" alt="arrow" />
                    </div>

                    {/* Right Arrow */}
                    <div
                        className={`absolute top mt-[-125px] pl-[22px] transform -translate-y-1/4  cursor-pointer`}
                        onClick={() =>
                            setCurrentSlide(
                                currentSlide === slides.length - 1 ? 0 : currentSlide + 1
                            )
                        }
                    >
                   <img src={infrarrowleftmobile} className="w-[72%] mr-[17px]" alt="arrow" />
                     </div>
                </div>
            </div>

          {/* desktop Slides */}
           
          

        </div>
    )
}

export default ImageSlider;