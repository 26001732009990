import React, { useState, useEffect } from 'react';
import Image1 from '../../Component Library/banner1.jpg';
import Image2 from '../../Component Library/banner2.jpg';
import Image3 from '../../Component Library/banner3.jpg';
import Image4 from '../../Component Library/banner4.jpg';
import Image5 from '../../Component Library/banner5.jpg';
import Image6 from '../../Component Library/bannersix.jpg';
import Image7 from '../../Component Library/banner7.jpg';
import Image8 from '../../Component Library/banner8.jpg';
import mbImage1 from '../../Component Library/sustainmbbanner1.png';
import mbImage2 from '../../Component Library/sustainmbbanner2.png';
import mbImage3 from '../../Component Library/sustainmbbanner3.png';
import mbImage4 from '../../Component Library/sustainmbbanner4.png';
import mbImage5 from '../../Component Library/sustainmbbanner5.png';
import mbImage6 from '../../Component Library/sustainmbbannersix.png';
import mbImage7 from '../../Component Library/sustainmbbanners7.png';
import mbImage8 from '../../Component Library/sustainmbbanner4.png';


// import Image3 from '../../Component Library/banner3.png';
const HeroSections = () => {
  const [currentImage, setCurrentImage] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage(prevImage => (prevImage === 8 ? 1 : prevImage + 1));
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
    <div className='desktop xl:block sm:hidden'>
    <div className="relative w-full sm:h-[657px] xl:h-[950px] overflow-hidden">
      <img
        src={Image1}
        alt="Image 1"
        className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
          currentImage === 1 ? 'opacity-100' : 'opacity-0'
        }`}
      />
      <img
        src={Image2}
        alt="Image 2"
        className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
          currentImage === 2 ? 'opacity-100' : 'opacity-0'
        }`}
      />
      <img
        src={Image3}
        alt="Image 3"
        className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
          currentImage === 3 ? 'opacity-100' : 'opacity-0'
        }`}
      />
      <img
        src={Image4}
        alt="Image 4"
        className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
          currentImage === 4 ? 'opacity-100' : 'opacity-0'
        }`}
      />
      <img
        src={Image5}
        alt="Image 5"
        className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
          currentImage === 5 ? 'opacity-100' : 'opacity-0'
        }`}
      />
      <img
        src={Image6}
        alt="Image 6"
        className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
          currentImage === 6 ? 'opacity-100' : 'opacity-0'
        }`}
      />
      <img
        src={Image7}
        alt="Image 7"
        className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
          currentImage === 7 ? 'opacity-100' : 'opacity-0'
        }`}
      />
      <img
        src={Image8}
        alt="Image 8"
        className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
          currentImage === 8 ? 'opacity-100' : 'opacity-0'
        }`}
      />
    
    </div>


      <div>

           

        </div> 

    </div>


  









</div>


    










  );
};

export default HeroSections;