import React, { useState } from 'react';
import { IonIcon } from '@ionic/react';
import { Link } from 'react-router-dom';
import sunknitLogo from '../../Component Library/sunKnitLogo.svg';
import hamburg from '../../Component Library/svg/menuicon.png';
import facebook from '../../Component Library/svg/facebook.svg';
import linked from '../../Component Library/svg/linkedin.svg';
import youtube from '../../Component Library/svg/youtube.svg';

import closeicon from '../../Component Library/svg/closeicon.png';

const SustainMobileNavbar = () => {
  const [menuOpen, setMenuOpen] = useState(false); // Initialize to false to hide the menu by default

  const toggleMenu = () => {
    setMenuOpen(prevState => !prevState);
  };

  return (
    <nav className="p-5 bg-transparent  md:flex md:items-center md:justify-between">
      <div className="flex justify-between items-center ">
        <span className="text-2xl font-Poppins cursor-pointer">
          <Link to="/"><img src={sunknitLogo} width='125px' height='58px'/></Link>
        </span>

        <span className="text-3xl cursor-pointer mx-2 md:hidden block text-black" onClick={toggleMenu}>
          {menuOpen ? (
            <img src={closeicon} width='25px' height='58px'/>
          ) : (
            <img src={hamburg} width='25px' height='58px'/>
          )}
        </span>
      </div>

      {menuOpen && (
        <ul className="md:flex md:items-center mt-[72px]">
          <li className="mx-4 my-6 md:my-0 font-poppins text-[20px]">
            <Link to="/">Home</Link>
          </li>
          <li className="mx-4 my-6 md:my-0 font-poppins text-[20px]">
            <Link to="/about">About us</Link>
          </li>
          <li className="mx-4 my-6 md:my-0 font-poppins text-[20px]">
            <Link to="/infrastructure">Infrastructure</Link>
          </li>
          <li className="mx-4 my-6 md:my-0 font-poppins text-[20px]">
            <Link to="/products">Products</Link>
          </li>
          <li className="mx-4 my-6 md:my-0 font-poppins text-[20px]">
            <Link to="/sustainability">Sustainability</Link>
          </li>
          <li className="mx-4 my-6 md:my-0 font-poppins text-[20px]">
            <Link to="/contact">Contact us</Link>
          </li>
          <div>
            <Link to="/contact"><button className='font-poppins font-normal px-8 py-1 bg-[#D65B03] ml-[22px] rounded-[22px] text-white'>Enquire</button></Link>
          </div>
          <div className='mt-[72px]'>
            <div className='flex itmes-center justify-center text-[21px] font-normal mb-[12px]'>Follow us</div>
            <div className='flex items-center justify-center gap-[20px]'>
            <img src={facebook} width='37px' height='58px'/>
            <img src={linked} width='37px' height='58px'/>
            <img src={youtube} width='37px' height='58px'/>

            </div>
          </div>
        </ul>
      )}
    </nav>
  );
};

export default SustainMobileNavbar;
