import React from "react";
import { TabsNew } from "./envioremntSection";
import arrow from '../../Component Library/bottomarow.png'
import imag1 from '../../Component Library/svg/fair1.png'
import imag2 from '../../Component Library/svg/fair2.png'
import imag3 from '../../Component Library/svg/fair3.png'

export const SustainabilityComponentThree = () => {

  const img1 = {
    backgroundImage: `url(${imag1})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  const img2 = {
    backgroundImage: `url(${imag2})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  const img3  = {
    backgroundImage: `url(${imag3 })`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };
  return (
     <div>
      <div className="desktop hidden xl:block mt-[-292px] ">
    <div className="flex flex-col w-screen h-[1253px] ">
      <div className="flex flex-col w-full h-[30%] 2xl:mt-[1px] md:mt-[294px] ">
        <div className="w-full flex items-center justify-center 2xl:text-[64px] md:text-[54px] font-semibold text-[#252525] font-poppins tracking-customs1">Fair Wear Commitment</div>
        <div className="w-[79%] items-center justify-center 2xl:text-[24px] md:text-[19px] font-normal text-[#333] text-center font-medium  font-poppins mx-auto pt-[22px]">Sun Knit Fashion is dedicated to Fair Wear practices for over a decade, ensuring compliance with labor laws, secure working conditions, and equitable pay.</div>
      </div>
      <div className="flex flex-col w-full h-[70%] md:mt-[54px] 2xl:mt-[-142px] ">
        <TabsNew />
      </div>
    </div>
    </div>

    <div className="mobile xl:hidden sm:block mt-[22px]">
       <div className="font-poppins font-bold text-[25px] text-black w-[89%] mx-auto leading-[1.5rem] pt-[22px] ">Fair Wear <br></br> Commitment</div>
       <div className="text-[12px] leading-[1.4rem] font-poppins w-[89%] mx-auto mt-[12px]">Sun Knit Fashion is dedicated to Fair Wear practices for over a decade, ensuring compliance with labor laws, secure working conditions, and equitable pay.
</div>
<div className="tabsec1 mt-[22px] w-[89%] mx-auto">
  <div className="text-[17px] font-poppins text-center text-black">ENVIRONMENT</div>
  <img src={arrow} className="w-[5%] mx-auto"/>
  <div className="img1 w-full h-[459px] w-[89%] mx-auto rounded-[25px] mt-[12px]" style={img1}>
     <div className="text-[22px] font-bold font-poppins text-white text-right mr-[22px] pt-[152px] mt-[2px] ">Environmental</div>
     <div className="text-[22px] font-normal font-poppins text-white text-right mr-[22px]">Wellness</div>
     <div className="text-[11px] font-normal font-poppins text-white w-[74%] mt-[8px] ml-[84px] leading-[1.4rem]">  Sun Knit places a high priority on the<br></br>
    environment, air and water quality and <br></br> stressor reduction (e.g., greenhouse gas<br></br> emissions). Keeping up biodiversity ensures
    <br></br> that natural resources will always be<br></br> available, which improves human health<br></br> and promotes environmental longevity.
</div>
  </div>
</div>
<div className="tabsec2 mt-[22px] w-[89%] mx-auto">
  <div className="text-[17px] font-poppins text-center text-black">SOCIAL</div>
  <img src={arrow} className="w-[5%] mx-auto"/>
  <div className="img1 w-full h-[459px] w-[89%] mx-auto rounded-[25px] mt-[12px]" style={img2}>
  <div className="text-[22px] font-bold font-poppins text-white text-left ml-[22px] pt-[22px] pt-[29px]">Social Welfare</div>
     <div className="text-[11px] font-normal font-poppins text-white w-[74%] ml-[22px] leading-[1.4rem]"> Sun Knit encourages social sustainability, which includes resource security, health, education and justice for the environment. Achieving a harmonic balance between social, economic <br></br>
and environmental objectives <br></br>
can be facilitated by placing <br></br>
a high priority on <br></br>
employee well-being.
</div>
  </div>
</div>
<div className="tabsec1 mt-[22px] w-[89%] mx-auto">
  <div className="text-[17px] font-poppins text-center text-black">ECONOMIC</div>
  <img src={arrow} className="w-[5%] mx-auto" />
  <div className="img1 w-full h-[459px] w-[89%] mx-auto rounded-[25px] mt-[12px]" style={img3}>
  <div className="text-[22px] font-bold font-poppins text-white text-left ml-[22px] pt-[29px]">Ecnomic Awareness</div>
     <div className="text-[11px] font-normal font-poppins text-white w-[74%] ml-[22px] leading-[1.4rem]"> By fostering employment growth, profitability and ethical ecological service accounting, we advance economic sustainability. Sun Knit understands that by lowering operational costs and reducing the intensity of resource extraction, environmental sustainability initiatives like <br></br>
recycling can improve <br></br>
economic sustainability.


</div>
  </div>
   
</div>
    </div>
    </div>
  )
}