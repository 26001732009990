import React, { useState, useEffect } from 'react';

import mbImage1 from '../../Component Library/sustainmbbanner1.png';
import mbImage2 from '../../Component Library/sustainmbbanner2.png';
import mbImage3 from '../../Component Library/sustainmbbanner3.png';
import mbImage4 from '../../Component Library/sustainmbbanner4.png';
import mbImage5 from '../../Component Library/sustainmbbanner5.png';
import mbImage6 from '../../Component Library/sustainmbbannersix.png';
import mbImage7 from '../../Component Library/sustainmbbanners7.png';
import mbImage8 from '../../Component Library/sustainmbbanner4.png';


// import Image3 from '../../Component Library/banner3.png';
const MbSections = () => {
  const [currentImage, setCurrentImage] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage(prevImage => (prevImage === 8 ? 1 : prevImage + 1));
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
   
    <div className='desktop xl:hidden sm:block'>
    <div className='desktop xl:hidden sm:block'>
    <div className="relative w-full sm:h-[657px] xl:h-[950px] overflow-hidden">
      <img
        src={mbImage1}
        alt="Image 1"
        className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
          currentImage === 1 ? 'opacity-100' : 'opacity-0'
        }`}
      />
      
      <img
        src={mbImage2}
        alt="Image 1"
        className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
          currentImage === 2 ? 'opacity-100' : 'opacity-0'
        }`}
      />
      <img
        src={mbImage3}
        alt="Image 3"
        className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
          currentImage === 3 ? 'opacity-100' : 'opacity-0'
        }`}
      />
      <img
        src={mbImage4}
        alt="Image 4"
        className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
          currentImage === 4 ? 'opacity-100' : 'opacity-0'
        }`}
      />
      <img
        src={mbImage5}
        alt="Image 5"
        className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
          currentImage === 5 ? 'opacity-100' : 'opacity-0'
        }`}
      />
      <img
        src={mbImage6}
        alt="Image 6"
        className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
          currentImage === 6 ? 'opacity-100' : 'opacity-0'
        }`}
      />
      <img
        src={mbImage7}
        alt="Image 7"
        className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
          currentImage === 7 ? 'opacity-100' : 'opacity-0'
        }`}
      />
      <img
        src={mbImage8}
        alt="Image 8"
        className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
          currentImage === 8 ? 'opacity-100' : 'opacity-0'
        }`}
      />
     
    </div>


      <div>

           

        </div> 




    </div>



    </div>






  );
};

export default MbSections;