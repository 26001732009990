import React, { useRef } from 'react';
import name from '../../Component Library/name.svg'
import phone from '../../Component Library/phone.svg'
import email from '../../Component Library/email.svg'
import message from '../../Component Library/message.svg'
import sunknitLogo from '../../Component Library/sunKnitLogo.svg'
import fblogo from '../../Component Library/fblogo.svg'
import linkedinlogo from '../../Component Library/linkedinlogo.svg'
import youtubelogo from '../../Component Library/youtubelogo.svg'
import { Link } from 'react-router-dom';
import Footer from '../Components/footer';
import { FooterMobile } from '../Components/fotterMobile';
import emailjs from '@emailjs/browser';

const ContactMain=() => {

  const form = useRef<HTMLFormElement>(null);

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); 
    // Your email sending logic here
    if (form.current) {
      emailjs.sendForm('service_07fl8vi', 'template_q0w97xn', form.current, '_pVfs4jxe4DVKU6mU');
    } else {
      console.error('Form ref is null');
    }
  };
  
  return (
    <div>
      <div className='flex flex-col items-center justify-center w-screen hidden xl:block'>
        <div className='h-[60%] w-full flex items-center justify-center xl:flex-row sm:flex-col '>
          <div className='flex xl:flex-col sm:flex-row  items-center justify-center h-full w-[40%]'>
            <div className='2xl:text-[73px] md:text-[65px] font-semibold text-[#D65B03] 2xl:w-[60%] md:w-[90%] font-poppins '>Contact Us</div>
            <div className='2xl:text-[24px] md:text-[22px] font-normal 2xl:w-[60%] md:w-[90%] font-poppins 2xl:ml-[-19px] md:ml-[-22px] '>Your questions & inquiries matter,<br></br> we're here to assist.</div>

          </div>
          <form ref={form} onSubmit={sendEmail}>
          <div className='flex xl:flex-col  sm:flex-row items-start justify-center h-[944px] xl:w-[60%] sm:w-[100%] gap-[40px]'>
            <div className='flex xl:flex-row sm:flex-col xl:w-[190%] sm:w-[100%] gap-[20px] '>
              <img src={name} />
              <input name='from_name' placeholder='    Name' className='h-[56px] rounded-[50px] bg-[#F4F4F4] text-[#B1B1B1]  w-full items-center justify-center flex font-poppins  2xl:text-[22px] md:text-[18px] font-normal' />
            </div>
            <div className='flex xl:flex-row sm:flex-col   w-[80%] gap-[10px] ml-[44px] '>
              <div className='flex flex-row items-center relative xl:w-[73%] sm:w-[100%] border rounded-[34px] bg-[#F4F4F4] p-4'>
                <input
                  type='radio'
                  value='Buyer'
                  id='buyerRadio'
                  name='from_selector'
                  className='border border-gray-300 p-2 rounded-[24px] mr-2 appearance-none focus:outline-none checked:bg-orange-500  ' // Use Tailwind CSS utility classes
                />
                <label
                  htmlFor='buyerRadio'
                  className='text-[#B1B1B1] 2xl:text-[22px] md:text-[18px] font-normal font-poppins ml-1 font-normal '
                >
                  Buyer
                </label>
              </div>
              <div className='flex flex-row items-center relative w-[73%] border rounded-[34px] bg-[#F4F4F4] p-2'>
                <input
                  type='radio'
                  value='Seller'
                  id='sellerRadio'
                  name='from_selector'
                                    className='border border-gray-300 p-2 rounded-[24px] mr-2 appearance-none focus:outline-none checked:bg-orange-500 checked:border-blue-500'
                />
                <label
                  htmlFor='sellerRadio'
                  className='text-[#B1B1B1]  font-poppins ml-1 2xl:text-[22px] md:text-[18px] font-normal'
                >
                  Seller
                </label>
              </div>
              <div className='flex flex-row items-center relative w-[73%] border rounded-[34px] bg-[#F4F4F4] p-4'>
                <input
                  type='radio'
                  value='Admin'
                  id='adminRadio'
                  name='from_selector'              
                      className='border border-gray-300 p-2 rounded-[24px] mr-[12px] appearance-none focus:outline-none checked:bg-orange-500 checked:border-blue-500'
                />
                <label
                  htmlFor='adminRadio'
                  className='text-[#B1B1B1] 2xl:text-[22px] md:text-[18px] font-normal font-poppins ml-1'
                >
                  Other
                </label>
              </div>
            </div>
            <div className='flex xl:flex-row sm:flex-col xl:w-[190%] sm:w-[100%] gap-[20px] '>
              <img src={phone} />
              <input name='phone' type="tel" placeholder='     Phone' className=' h-[56px] rounded-[50px] bg-[#F4F4F4] w-full items-center justify-center flex font-poppins 2xl:text-[22px] md:text-[18px] font-normal' />
            </div>
            <div className='flex xl:flex-row sm:flex-col xl:w-[190%] sm:w-[100%] gap-[20px] '>
              <img src={email} />
              <input  type='email' name='from_email' placeholder='    Email' className='h-[56px] rounded-[50px] bg-[#F4F4F4] w-full items-center justify-center flex font-poppins 2xl:text-[22px] md:text-[18px] font-normal' />
            </div>
            <div className='flex xl:flex-row sm:flex-col xl:w-[190%] sm:w-[100%] gap-[20px] '>
              <img src={message} />
              <input name='message' placeholder='    Message' className='h-[156px] rounded-[50px] bg-[#F4F4F4] w-full items-center justify-center flex font-poppins 2xl:text-[22px] md:text-[18px] font-normal' />
            </div>

            <div>
              <button className='text-[#b1b1b1] bg-black px-8 py-3 rounded-[27px] ml-[47px] text-[24px] font-normal'>SUBMIT</button>
            </div>

          </div>
            </form>
        </div>
        <div>
          <Footer />
        </div>
      </div>

      {/* mobile view start*/}

      <div className='h-[672px] bg-white xl:hidden sm:block mt-[-124px]'>
        <div className='text-[33px] pt-[17px] font-semibold text-[#D65B03] w-[60%] font-poppins mx-auto flex items-center justify-center'>Contact Us</div>
        <div className='text-[17px] mt-[7px] font-normal text-[#262626] w-[90%] font-poppins mx-auto flex items-center justify-center text-center '>Your questions & inquiries matter,<br></br> we're here to assist.</div>
        <br></br>
        <div className='form w-[85%] mx-auto'>
          <div className='flex mx-auto gap-[20px] '>
            <img src={name} className='w-[7%]' />
            <input placeholder='    Name' className='h-[39px] rounded-[10px] bg-[#F4F4F4] text-[#B1B1B1]  w-full items-center justify-center flex font-poppins  text-[17px] font-normal' />
          </div>
          <div className='flex gap-[10px] w-[78%] mx-auto mt-[24px] ml-[54px]'>
            <div className='flex flex-row items-center relative xl:w-[33%] sm:w-[33%] border rounded-[34px] bg-[#F4F4F4] p-1'>
              <input
                type='radio'
                value='Buyer'
                id='buyerRadio'
                name='userRole'
                className='p-1 rounded-[24px] mr-2 appearance-none focus:outline-none checked:bg-orange-500  ' // Use Tailwind CSS utility classes
              />
              <label
                htmlFor='buyerRadio'
                className='text-[#B1B1B1] font-normal font-poppins ml-1 text-[12px] font-normal '
              >
                Buyer
              </label>
            </div>
            <div className='flex flex-row items-center relative xl:w-[33%] sm:w-[33%] border rounded-[34px] bg-[#F4F4F4] p-1'>
              <input
                type='radio'
                value='Buyer'
                id='buyerRadio'
                name='userRole'
                className='  p-1 rounded-[24px] mr-2 appearance-none focus:outline-none checked:bg-orange-500  ' // Use Tailwind CSS utility classes
              />
              <label
                htmlFor='buyerRadio'
                className='text-[#B1B1B1]  font-normal font-poppins ml-1 text-[12px] font-normal '
              >
                Seller
              </label>
            </div>
            <div className='flex flex-row items-center relative xl:w-[33%] sm:w-[33%] border rounded-[34px] bg-[#F4F4F4] p-1'>
              <input
                type='radio'
                value='Buyer'
                id='buyerRadio'
                name='userRole'
                className='p-1 rounded-[24px] mr-2 appearance-none focus:outline-none checked:bg-orange-500  ' // Use Tailwind CSS utility classes
              />
              <label
                htmlFor='buyerRadio'
                className='text-[#B1B1B1] font-normal font-poppins ml-1 text-[12px] font-normal '
              >
                Other
              </label>
            </div>
          </div>
          <div className='flex  gap-[20px] mt-[24px] '>
            <img src={phone} className='w-[7%]' />
            <input type='number' placeholder='     Phone' className=' h-[39px] rounded-[14px] bg-[#F4F4F4] w-full items-center justify-center flex font-poppins text-[17px] font-normal' />
          </div>
          <div className='flex gap-[20px] mt-[24px] '>
            <img src={email} className='w-[7%]' />
            <input type='email' placeholder='    Email' className='h-[39px] rounded-[14px] bg-[#F4F4F4] w-full items-center justify-center flex font-poppins text-[17px] font-normal' />
          </div>
          <div className='flex  gap-[20px] mt-[24px]'>
            <img src={message} className='w-[7%] pt-[124px]' />
            <input placeholder='    Message' className='h-[196px] rounded-[14px] bg-[#F4F4F4] w-[84%] items-center justify-center flex font-poppins text-[17px] font-normal' />
          </div>
        </div>

        <div className='text-[#b1b1b1] text-center bg-black  rounded-[14px] mt-[28px] ml-[77px] pt-[5px] text-[15px] w-[82px] h-[33px] font-normal'>SUBMIT</div>

      </div>
      <div className='xl:hidden sm:block'>
        <FooterMobile/>
        {/* <div className='footer w-[99%] h-[226px] bg-black mx-auto mt-[34px] flex '>
          <div className='flex flex-col w-[50%] bg-black h-[144px] ml-[42px] mt-[32px]'>
            <div className='font-normal text-white font-poppins text-[19px] pb-[14px]'>
              <Link to="/">
                Home
              </Link>
            </div>
            <div className='font-normal text-white font-poppins text-[19px] pb-[14px]'>
              <Link to="/about">About us</Link>

            </div>
            <div className='font-normal text-white font-poppins text-[19px] pb-[14px]'>
              <Link to="/infrastructure">Infrastructure</Link>

            </div>
            <div className='font-normal text-white font-poppins text-[19px]'>
              <Link to="/products">
                Products
              </Link>
            </div>
          </div>
          <div className='flex flex-col w-[50%] bg-black h-[144px] mt-[32px]'>
            <div className='font-normal text-white font-poppins text-[19px] mb-[14px]'>
              <Link to="/sustainability">
                Sustainbility
              </Link>
            </div>

            <div className='font-normal text-white font-poppins text-[19px]'>
              <Link to="/contact">
                Conatct Us
              </Link>
            </div>
          </div>
        </div> */}
        {/* <div className='bg-black w-[99%] mx-auto h-[544px]'>
          <div className='w-[50%] flex mx-auto'> <img src={sunknitLogo} className='h-[174px]' /></div>
          <div className='text-center text-white text-[15px] leading-[2.4rem]'>S.F.NO: 250/1,</div>

          <div className='text-center text-white text-[15px] leading-[2.4rem]'>24,25 Kanchi Kamatchi Nagar,</div>

          <div className='text-center text-white text-[15px] leading-[2.4rem]'>Kangayampalayam Pudur,Kangayam Road,</div>

          <div className='text-center text-white text-[15px]'>Tirupur - 641 064</div>

          <div className='h-[1px] w-[70%] bg-[#ffff] mt-[28px] mx-auto'></div>

          <div className='text-[17px] text-white font-medium text-center mt-[24px]'>Follow Us</div>
          <div className='flex flex-row gap-[25px] items-center justify-center mt-[24px]'>
            <img src={fblogo}></img>
            <img src={linkedinlogo}></img>
            <img src={youtubelogo}></img>
          </div>
          <div className='text-[14px] font-medium text-white text-center mt-[24px]'>2024  Sunknit Garments . All Rights Reserved</div>

        </div> */}
      </div>
      {/* mobile view end*/}
    </div>
  );
};

export default ContactMain;
