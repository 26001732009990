import React from 'react';
import { NavBar } from '../Components/navBar';
import sunknitLogo from '../../Component Library/sunKnitLogo.svg'
import phoneIcon from '../../Component Library/phoneIcon.png'
 import messageIcon from '../../Component Library/Message.png'
import fblogo from '../../Component Library/fblogo.svg'
import linkedinlogo from '../../Component Library/linkedinlogo.svg'
import youtubelogo from '../../Component Library/youtubelogo.svg'
import dumy from '../../Component Library/dumy.png'
import bgframe from '../../Component Library/conatactFrame.png'
import contact from '../../Component Library/contactus.png'
import contactbanner from '../../Component Library/svg/contact banner.jpg'
import user from '../../Component Library/svg/user.svg'
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import Footer from '../Components/footer';
import ContactForm2 from './contactus2';
import email from '../../Component Library/svg/email.svg';
import phone from '../../Component Library/svg/phone.svg';

import message from '../../Component Library/svg/mess.svg';
import { FooterMobile } from '../Components/fotterMobile';
import MobileMenu from '../Components/navbarMobile';
import MobileNavbar from '../Components/navbarMobile';

export const Contact: React.FC = () => {
  const backgroundStyle = {
    backgroundImage: `url(${bgframe})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    
  };

  

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);


  return (
    <div>
    <div className='flex flex-col gap-[25px] hidden xl:block'>
      <NavBar />
      
      <img src={contactbanner} style={{backgroundSize:'cover'}}/>

      <div className='flex mx-auto w-[80%] mt-[22px] mb-[22px]'>
        <div className='w-[758px] h-screen flex flex-col  '>
          <ContactForm2/>
        </div>
        <div className='w-[758px] h-[946px] flex flex-col '>
          <div className='2xl:text-[95px] md:text-[54px] font-poppins font-semibold text-[#FF6B00] ml-[52px] mt-[140px]'>Contact Us</div>
          <div className='ml-[52px] 2xl:text-[25px] md:text-[20px] font-medium	tracking-customs font-poppins mt-[-12px]'>Your questions & inquiries matter, we're here to assist.</div>
          <div className='h-[1px] w-[20%] bg-black 2xl:mt-[59px] md:mt-[29px]  ml-[54px]'></div>
          <div className='font-medium 2xl:text-[32px] md:text-[28px] text-[#FF6B00]  ml-[52px] leading-[3.8rem] mt-[41px] font-poppins' >Address:</div>
          <div className='font-normal 2xl:text-[32px] md:text-[24px] text-[#0C0C0C] ml-[52px] leading-[3.8rem] font-poppins'>S.F.NO: 250/1, </div>
          <div className='font-normal 2xl:text-[32px] md:text-[24px] text-[#0C0C0C] ml-[52px] leading-[3.8rem] font-poppins'>Sri Kanchi Kamatchi Nagar,  </div>
          <div className='font-normal 2xl:text-[32px] md:text-[24px] text-[#0C0C0C] ml-[52px] leading-[3.8rem] font-poppins w-[144%]'>Kangayampalayam Pudur, Kangayam Road,</div>
          <div className='font-normal 2xl:text-[32px] md:text-[24px] text-[#0C0C0C] ml-[52px] leading-[3.8rem] font-poppins'>Tirupur - 641604</div>
          <div className='flex ml-[52px] mt-[44px]'>
            <div><img src={phone} alt="phone-icon" className="mr-2  2xl:w-[46px] 2xl:h-[46px] md:w-[31px] md:h-[31px] ml-[2px]" /></div>
            <div className='2xl:text-[32px] md:text-[24px] font-normal font-poppins	text-[#0C0C0C] ml-[22px]'>+91 93600 39949</div>
          </div>
          <div className='flex ml-[52px] mt-[22px]'>
          <div>
          <img src={email} alt="phone-icon" className="mr-2  2xl:w-[46px] 2xl:h-[46px] md:w-[31px] md:h-[31px] ml-[2px]" /></div>   
              <div className='2xl:text-[32px] md:text-[24px] font-normal font-poppins	 text-[#0C0C0C] ml-[22px]'>samy@sunknitfashions.com</div>
          </div>
        </div>
      </div>

 <div className='pt-[77px]'>
 <Footer/>
 </div>
 

    </div>
   

    <div className='mobile xl:hidden sm:block'>
      <div>
        <MobileNavbar/>
      <div className='w-[89%] mx-auto h-[883px] flex flex-col '>
          <div className='text-[20px] font-poppins text-[#FF6B00] ml-[2px] mt-[22px]'>Contact Us</div>
          <div className='ml-[px] text-[14px] font-normal	 font-poppins mt-[7px]'>Your questions & inquiries matter,<br></br> we're here to assist.</div>
          <div className='mt-[29px]'>
          <form className="bg-white p-8 rounded shadow-md w-full h-[526px] relative rounded-[22px] border border-[#cacaca]">
        <div className="mb-[124px] relative flex items-center justify-center mt-[22px]">
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Name"
            className="border-b border-gray-300 focus:outline-none focus:border-orange-500 px-2 py-1 w-[190%] text-[17px] font-poppins"
          />
          {/* <span className="icon"> <img src={user} alt="user-icon" className="ml-[-12px] h-12 w-12" /></span> */}
        </div>

        <div className="mb-[124px] relative flex items-center justify-center mt-[-72px]">
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            className=" border-b border-gray-300 focus:outline-none focus:border-orange-500 px-2 py-1 w-[190%] text-[17px] font-poppins"
          />
          {/* <span className="icon"><img src={email} alt="email-icon" className="mr-2 h-6 w-6" /></span> */}
        </div>

        <div className="mb-[124px] relative flex items-center justify-center mt-[-72px]">
          <input
            type="tel"
            id="mobile"
            name="mobile"
            placeholder="Phone Number"
            className=" border-b border-gray-300 focus:outline-none focus:border-orange-500 px-2 py-1 w-[190%] text-[17px] font-poppins"
          />
          {/* <span className="icon"><img src={phone} alt="phone-icon" className="mr-2 h-6 w-6" /></span> */}
        </div>

        <div className="mb-[124px] items-center justify-center mt-[-72px]">
          <textarea
            id="message"
            name="message"
            placeholder="Message"
            className=" border-b border-gray-300 focus:outline-none focus:border-orange-500 px-2 py-1 w-[100%] text-[17px] font-poppins"
          ></textarea>
            <button
  type="submit"
  className="border border-black w-[77px] h-[34px] rounded-[32px] focus:outline-none ml-[14px] mt-[24px] text-[14px] font-normal font-poppins"
>
  Submit
</button>
          {/* <span className="icon"><img src={message} alt="message-icon" className="mr-2 h-6 w-6" /></span> */}
        </div>
        {/* <div className='mt-[-87]'>
        <button
  type="submit"
  className="border border-black w-[77px] h-[34px] rounded-[32px] focus:outline-none ml-[14px] mt-[-84px] text-[14px] font-normal font-poppins"
>
  Submit
</button>
        </div> */}
 
      </form>
          </div>
          <div className='w-full'>
          <div className='font-medium text-[20px] text-[#FF6B00]  ml-[22px] leading-[1.4rem] mt-[24px]'>Address:</div>
          <div className='font-normal text-[17px] text-[#0C0C0C] ml-[22px] leading-[1.4rem] mt-[12px]'>S.F.NO: 250/1, </div>
          <div className='font-normal text-[17px] text-[#0C0C0C] ml-[22px] leading-[1.4rem]'>Sri Kanchi Kamatchi Nagar,  </div>
          <div className='font-normal text-[17px] text-[#0C0C0C] ml-[22px] leading-[1.4rem]'>Kangayampalayam Pudur, Kangayam Road,</div>
          <div className='font-normal text-[17px] text-[#0C0C0C] ml-[22px] leading-[1.4rem]'>Tirupur - 641604</div>
          <div className='flex ml-[22px] mt-[24px] '>
            <div><img src={phone} alt="phone-icon" className="mr-2  w-[19px] ml-[2px]" /></div>
            <div className='text-[17px] font-normal	text-[#0C0C0C] ml-[8px]'>+91 93600 39949</div>
          </div>
          <div className='flex ml-[22px] mt-[12px]'>
          <div>
            <img src={email} alt="message-icon" className="mr-2  w-[19px] mt-[8px]" /></div>   
              <div className='text-[17px] font-normal	 text-[#0C0C0C] ml-[8px]'>samy@sunknitfashions.com</div>
          </div>
          
         
          </div>
          
        </div>
        <div className='mt-[54px] w-full'>
          <FooterMobile/>
          </div>
      </div>
    </div>
    

    </div>

  )
}



