import React from 'react';
import { NavBar } from '../Components/navBar';
import handShake from '../../Component Library/handsake1.svg';
import industryMastery from '../../Component Library/industryMastery.svg';
import personStar from '../../Component Library/personStar.svg';
import dressCut from '../../Component Library/dressCut.svg';
import years from '../../Component Library/20plusyears.png'
import { Link } from "react-router-dom";
import knowmore from '../../Component Library/svg/arrow.svg';
const SecondPage = () => {


    return (
        <div >
              <div className='flex flex-col gap-[25px] mt-[282px] w-screen h-screen items-center justify-start  '>
          
           
          <img className='flex items-center justify-center xl:w-[24%] sm:w-[44%] md:mt-[124px] 2xl:mt-[-144px] sm:mt-[-279px]' src={years} />
     
      <div className='flex items-center jsutify-center xl:text-[60px] sm:text-[25px] xl:mt-[-112px] sm:mt-[-54px] font-medium font-poppins'>
          Years of Operations 

      </div>
      <div className='2xl:w-[75%] md:w-[85%] sm:w-full flex flex-col items-center justify-center gap-[10px] mt-[-51px]'>
          <div className='xl:text-[24px]  xl:mt-[2px] sm:ml-[42px] xl:ml-[12px]  sm:mt-[47px] font-medium flex w-full items-start justify-start font-poppins '>
              Who we are
          </div>
          <div className='xl:text-[24px] sm:text-[14px] font-light font-poppins xl:w-[100%]  xl:leading-[2.8rem] sm:leading-[1.8rem] hidden xl:block'>
              <span className='text-[#D65B03] font-semibold sm:text-left xl:ml-[2px] sm:ml-[22px] sm:w-full '>Superior Sustainable Fashion Creators: </span><br></br>
              <span className='xl:ml-[2px] sm:ml-[12px] mx-auto'>Sun Knit Fashions is an expert in ethical and ecological design in the garment industry. For over two decades, we have been producing stunning garments by skilfully blending fashion with a profound commitment to the environment and green production methods.</span>
          </div>
          <div className='xl:hidden sm:block mt-[-12px]'>
               
              <span className='ml-[22px] text-[#D65B03] font-semibold text-[14px] font-poppins'>Superior Sustainable Fashion Creators </span><br></br>
              <div className='w-[94%] ml-[22px] text-[14px] font-normal font-poppins'>Sun Knit Fashions is an expert in ethical and ecological design in the garment industry. For over two decades, we have been producing stunning garments by skilfully blending fashion with a profound commitment to the <br></br> environment and green production methods.</div>
          </div>
      </div>
      <div className='w-full 2xl:w-full md:w-[89%] sm:w-[100%] sm:mt-[-22px] flex xl:flex-row sm:flex-col  gap-[50px] pt-[71px] items-center justify-center'>
          <div className='2xl:w-[315px] md:w-[575px] 2xl:h-[306px] md:h-[276px] sm:w-[315px] sm:h-[276px] xl:mt-[6px] sm:mt-[-52px] bg-[#F4F4F4] flex items-center justify-center flex-col rounded-[50px] p-5 '>
              <div className='w-full h-[20%] 2xl:text-[24px] md:text-[22px]  font-semibold flex items-center justify-center flex-col font-poppins'>
                  <div>Ethical Decision</div>
                  <div>Makers </div>
              </div>
              <div className='w-full h-[50%] flex items-center justify-center'>
              <img src={handShake} height='80px' width='85px' />
              </div>
              <div className='w-[90%] h-[20%] text-[12px] font-medium	flex items-center justify-center text-center font-poppins text-[#000000]'>
                  We promptly provide the required assistance on time and push to achieve a better solution.
              </div>

          </div>

          <div className='2xl:w-[315px] md:w-[575px] 2xl:h-[306px] md:h-[276px] sm:w-[315px] sm:h-[276px] bg-[#F4F4F4] flex items-center justify-center flex-col rounded-[50px] p-5  xl:mt-[6px] sm:mt-[-38px] '>
              <div className='w-full h-[20%] text-[24px] font-semibold flex items-center justify-center flex-col font-poppins md:text-center '>
                  <div>Industry Mastery</div>

              </div>
              <div className='w-full h-[50%] flex items-center justify-center'>
                  <img src={industryMastery} height='60px' width='65px' />
              </div>
              <div className='w-[90%] h-[20%] text-[12px] font-medium	flex items-center justify-center text-center font-poppins'>
                  To ensure the highest quality, professional textile experts are assigned to respective units.
              </div>

          </div>

          <div className='2xl:w-[315px] md:w-[575px] 2xl:h-[306px] md:h-[276px] sm:w-[315px] sm:h-[276px] bg-[#F4F4F4] flex items-center justify-center flex-col rounded-[50px] p-5 xl:mt-[6px] sm:mt-[-38px]'>
              <div className='w-full h-[20%] text-[24px] font-semibold flex items-center justify-center flex-col font-poppins md:text-center'>
                  <div>Clients Concierge</div>

              </div>
              <div className='w-full h-[50%] flex items-center justify-center'>
                  <img src={personStar} height='60px' width='65px' />
              </div>
              <div className='w-[100%] h-[20%] text-[12px] font-medium	 flex items-center justify-center text-center font-poppins'>
                  Our dedicated crew is committed to making sure our clients attain satisfaction. Our #1 concern is making sure you're satisfied.
              </div>

          </div>

          <div className='2xl:w-[315px] md:w-[575px] 2xl:h-[306px] md:h-[276px] sm:w-[315px] sm:h-[276px] bg-[#F4F4F4] flex items-center justify-center flex-col rounded-[50px] p-5 xl:mt-[6px] sm:mt-[-38px]'>
              <div className='w-full h-[20%] text-[24px] font-semibold flex items-center justify-center flex-col font-poppins'>
                  <div>Soulfull </div>
                  <div>Craftmanship </div>

              </div>
              <div className='w-full h-[50%] flex items-center justify-center'>
                  <img src={dressCut} height='60px' width='65px' />
              </div>
              <div className='w-[90%] h-[20%] text-[12px] font-medium flex items-center justify-center text-center font-poppins'>
                  Passion, and accuracy,  go into every stitch. Our creators create fashion by putting their hearts into every piece.
              </div>

          </div>

      </div>
      <div className='w-full h-auto flex items-center justify-center pt-[50px] text-[32px] text-white '>
          <div className='flex flex-col w-[194px] h-[75px] font-poppins'>
          <Link to="/about">
  <button className='bg-[#4d4d4d] xl:text-[32px] sm:text-[14px] xl:rounded-[32px] sm:rounded-[12px] xl:w-[299px] xl:h-[74px] xl:pt-[14px] xl:pl-[44px] xl:mt-[44px] xl:ml-[1px] sm:ml-[39px] sm:mt-[-54px]  sm:w-[119px] sm:h-[30px] sm:pt-[4px] sm:pl-[17px]  flex'>
    Know More  <span className='flex xl:pt-[16px] xl:pl-[9px] sm:pt-[6px] sm:pl-[4px]'> <img src={knowmore} className='xl:h-[18px] xl:w-[14px] sm:h-[8px] sm:w-[8px]' /> </span>

  </button>
</Link>
          </div>

      </div>

      

  </div>
        </div>
      
    );
};

export default SecondPage
