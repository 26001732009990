import React, { CSSProperties } from 'react';
import sustainability from '../../Component Library/sustainability.png';
import { Link } from 'react-router-dom';
import sustainmobile1 from '../../Component Library/svg/sustainmobile1.jpg';
import sustainmobile2 from '../../Component Library/sustainmobile2.png';
import knowmorearrow from '../../Component Library/svg/arrow black.svg';
import knowmore from '../../Component Library/svg/arrow.svg';

const Sustainability: React.FC = () => {
    const backgroundStyle = {
        backgroundImage: `url(${sustainability})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat', 
      };
    
      return (
        <div>
        <div style={backgroundStyle} className='flex flex-col items-center justify-center h-screen w-screen hidden lg:block'>
    <div className='flex flex-row w-full h-[30%] items-center justify-center'>
        <div className='h-full w-[50%] flex items-center justify-center flex-col'>
            <div className='flex 2xl:text-[71px] md:text-[44px]  font-semibold text-[#7A9A0F] font-poppins 2xl:tracking-customs1 md:tracking-custom '>
                Sustainability
            </div>
            <div className='2xl:text-[28px] md:text-[18px] font-medium font-poppins tracking-customs'>We, Sun Knit Fashion, Care Nature and Future </div>
        </div>
        <div className='h-full w-[50%] flex items-center justify-center flex-col'>
            <div className='flex 2xl:text-[71px] md:text-[44px] font-bold text-[#5B6AD0] font-poppins 2xl:tracking-customs1 md:tracking-custom'>
                Solar Energy:
            </div>
            <div className='2xl:text-[28px] md:text-[18px] font-medium font-poppins tracking-customs'>Sustainably Fuelling Our Passion </div>
        </div>
    </div>
    <div className='flex w-full h-[60%] items-center justify-center'>
    <Link to="/sustainability">
        <button className='bg-[#e4e4e4] 2xl:text-[23px] md:text-[17px] rounded-[34px] py-[12px] px-[24px] flex'>
          Know More  <span className='flex 2xl:mt-[10px] md:mt-[9px] ml-[5px] 2xl:h-[15px] 2xl:w-[11px] md:w-[13px] md:h-[11px]'> <img src={knowmorearrow}  /> </span>

        </button>
      </Link>
</div>
    <div className='flex w-full h-[10%] items-center justify-center'></div>
</div>


 {/* mobile view start */}


<div className='bg-white h-[724px] xl:hidden '>
  <div className='w-[343px] h-[209px] mx-auto mt-[-89px] '>
<img className='pt-[43px] ' src={sustainmobile1}/>
<div className='flex'>
<div className='w-[109px] rounded-[12px] h-[30px]  mx-auto flex text-[14px] text-white pl-[12px] pt-[4px] bg-[#718b1a] mt-[12px] '>Know More<span><img src={knowmore} className='sm:h-[8px] sm:w-[8px] mt-[8px] ml-[4px]' /></span></div>
</div>
  </div>
  <br></br>
  <div className='w-[343px] h-[209px] mx-auto mt-[44px] '>
<img className='pt-[43px] 'src={sustainmobile2} />
<div className='flex'>
<div className='w-[109px] rounded-[12px] h-[30px]  mx-auto flex text-[14px] text-white pl-[12px] pt-[4px] bg-[#5b6ad0] mt-[12px] '>Know More<span><img src={knowmore} className='sm:h-[8px] sm:w-[8px] mt-[8px] ml-[4px]' /></span></div>
</div>  </div>
</div>

     {/* mobile view end*/}



</div>
      );
    };
    
export default Sustainability;
