import React from 'react';
// import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { Contact } from './Sun Knit/Pages/contactNew';
import { InfraPage1 } from './Sun Knit/Pages/infrastraucture1';
import { AboutUs } from './Sun Knit/Pages/aboutUs';
import { Home } from './Sun Knit/Pages/home';
import { Sustainabilitys } from './Sun Knit/Pages/sustainability';
import { ProductsPage } from './Sun Knit/Pages/PrdouctsPage';
import { PrdouctsNew } from './Sun Knit/Pages/products2';
import ThreeColumnLayout from './Sun Knit/Pages/test';




function App() {
  return (

    <Router>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route
          path="/infrastructure"
          element={<InfraPage1 />} />
        <Route path="/sustainability" element={<Sustainabilitys />} />
        <Route
          path="/contact"
          element={<Contact />}
        />
        <Route
          path="/products"
          element={<ProductsPage/>}
        />

       <Route
          path="/productsnew"
          element={<PrdouctsNew/>}
        />
      </Routes>
    </Router>
   
    
  );
}

export default App;
