import React from "react";
import { NavBar1 } from "../Components/navBar1";
import fabrics from '../../Component Library/productbanner.png'
import halfsleeve from '../../Component Library/Halfsleve.png'
import tshirts1 from '../../Component Library/t-shirts-1.png'
import tshirts2 from '../../Component Library/t-shirts-2.png'
import tshirts3 from '../../Component Library/t-shirts-3.png'
import demonstrateframe from '../../Component Library/demonstrate-frame.png'
import demonstrateframe1 from '../../Component Library/demonstrate-frame1.png'
import best from '../../Component Library/bestinquality.png'
import quality1 from '../../Component Library/quality1.png'
import quality2 from '../../Component Library/quality2.png'
import quality3 from '../../Component Library/quality3.png'
import whatmakes from '../../Component Library/WhatMakes1.png'
import frame1 from '../../Component Library/frame1.png'
import frame2 from '../../Component Library/frame2.png'
import frame3 from '../../Component Library/frame3.png'
import frame4 from '../../Component Library/svg/frame4.png'
import frame5 from '../../Component Library/svg/frame5.png'
import frame6 from '../../Component Library/svg/frame6.png'
import frame from '../../Component Library/fittdsgnbg.png'
import frameimg from '../../Component Library/fittdesign.png'
import secondImage from '../../Component Library/WhatMakes2.png'
import hoodie from '../../Component Library/svg/hoodie2.png'
import hoodie1 from '../../Component Library/svg/hoodie1.png'
import pant1 from '../../Component Library/svg/pant1.png'
import pant2 from '../../Component Library/svg/pant2.png'
import manbg from '../../Component Library/menbannerdesktop.png'
import mbbanner from '../../Component Library/product_hero.png'
import hoodiephone from '../../Component Library/svg/hoodie1phone.png'
import mbpant from '../../Component Library/svg/mbpant1.png'
import womenmb1 from '../../Component Library/svg/womenmb-1.png'
import womenmb2 from '../../Component Library/svg/womenmb-2.png'
import womenmb3 from '../../Component Library/svg/womenmb-3.png'
import fittdesign from '../../Component Library/svg/mblast.jpg'
import whatmakes1 from '../../Component Library/product_yellow.png'
import paper from '../../Component Library/paperdk.svg'
import bttle from '../../Component Library/bottledk.png'
import fishnets from '../../Component Library/fishnetdk.png'
import fishscale from '../../Component Library/fishscalesdk.png'
import organic from '../../Component Library/organiccotton.png'
import { useEffect } from "react";
import LazyLoad from 'vanilla-lazyload';
import { Link } from "react-router-dom";
import Footer from "../Components/footer";
import { FooterMobile } from "../Components/fotterMobile";
import MobileNavbar from "../Components/navbarMobile";
import MobileNavbar2 from "../Components/productMobile";
import MobilePrdouct from "./PrdouctMobile";
export function ProductsPage() {




  const fittframe = {
    backgroundImage: `url(${frame})`,

    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };


  const manbanner = {
    backgroundImage: `url(${manbg})`,

    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };


  const lastsection = {
    backgroundImage: `url(${fittdesign})`,

    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };


  // const bgproducts = {
  //   backgroundImage: `url(${halfleeve})`,

  //   backgroundSize: 'contain',
  //   backgroundPosition: 'center',
  //   backgroundRepeat: 'no-repeat',
  //   width:'1655px',
  //   height:'727px'
  // };



  // const frameimg = "../../Component Library/fittdesign.png"; // Replace with your image path


  const textContainer = {
    position: "absolute" as "absolute", // Explicitly set the type
    bottom: 0,
    left: 0,
    padding: "16px",
    color: "white",
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  // Initialize LazyLoad
const lazyLoad = new LazyLoad({
  elements_selector: ".lazy", // CSS selector for lazy loading elements
  // Additional configuration options can be added here
});


  return (
    <div>
      <div className="hidden xl:block">
        <div className="h-[1054px] w-screen bg-black">
          <div className="w-full h-[153PX]">
            <NavBar1 />
          </div>
          <div className="">
            <div className="w-[98%] h-[862PX] bg-[#0D0D0D] mx-auto rounded-[45px]">
              <div className="text-[64px] font-bold font-poppins flex items-center justify-center pt-[44px] text-white">Knit & Weave.</div>
              <div className="text-[32px] font-normal font-poppins flex items-center justify-center pt-[9px] text-white">We create an Extensive collection of Fabrics for Kids, Ladies and Men's</div>
              <img src={fabrics} className="lazy flex w-[79%] items-centerjustify-center mx-auto mt-[94px]" />
            </div>
          </div>

        </div>
       <div className="hidden 2xl:block md:hidden">
       <div className="2xl:bg-black md:bg-black w-full ">
       <div className="2xl:h-[1195px] 2xl:w-[94%] md:w-[94%] mx-auto flex bg-black ">
         <div className="2xl:w-[40%] md:w-[30%] h-full 2xl:bg-black  flex flex-col">
            <div className="2xl:w-[50%] md:w-[50%] gradient-understand  2xl:w-[525px] 2xl:h-[571px] 2xl:rounded-[55px] 2xl:mt-[295px] ">
              <div className="2xl:text-[24px] 2xl:font-poppins 2xl:font-light 2xl:text-white 2xl:pt-[107px] 2xl:pl-[71px]">Leveraging</div>
              <div className="2xl:text-[75px] 2xl:font-poppins 2xl:font-medium 2xl:pt-[8px] 2xl:pl-[71px] 2xl:leading-[4.7rem] tracking-custom gradient-recycle"> Recycled Fabrics</div>
              <div className="2xl:text-[18px] 2xl:font-poppins 2xl:font-normal 2xl:pt-[42px] 2xl:pl-[71px] 2xl:w-[94%] text-white">At Sun Knits, we elevate sustainability by innovatively recycling waste into unique fabrics, expanding our eco-friendly product range while reducing environmental impact.</div>
            </div>
            <div className='w-[181px] h-[2px] gradient-bdr mt-[-294px] ml-[474px] '></div>
         </div>
         <div className="part2">
         <div className="2xl:w-[100%] md:w-[50%] h-[244px]  flex flex-row">
          <div className="gap-[112px] flex mt-[118px]">
          <div className="flex flex-col w-[50%] gradient-understa 2xl:w-[421px] 2xl:h-[391px] md:w-[221px] 2xl:h-[191px] flex mx-auto rounded-[55px]">
            <div className="flex flex-row mt-[57px]">
              <div className="w-[376px] h-[164px] ml-[37px] flex">
                <img src={paper} className="lazy" />
                <div className="2xl:text-[26px] leading-[1.7rem] font-poppins font-medium text-[#9f9f9f] mt-[56px] ml-[12px]">Eco-friendly paper</div>
              </div> 
            </div>
            <div className=" ml-[37px] font-poppins text-[13px] leading-[1.9rem] text-white mt-[25px] font-light ">Pulp from paper waste can be converted into fibers,<br></br> spun into yarn, and woven into fabric, offering eco-<br></br>friendly alternatives in apparel.
</div>
          </div>

          
          <div className="flex flex-col w-[50%] gradient-understa w-[421px] h-[391px] flex mx-auto rounded-[55px]">
            <div className="flex flex-row mt-[57px]">
              <div className="w-[376px] h-[164px] ml-[37px] flex">
                <img src={bttle} className="lazy"/>
                <div className="2xl:text-[26px] leading-[1.7rem] font-poppins font-medium text-[#9f9f9f] mt-[56px] ml-[12px]">Used Plastic bottles</div>
              </div> 
            </div>
            <div className=" ml-[37px] font-poppins text-[13px] leading-[1.9rem] text-white mt-[25px] font-light ">Recycled plastic bottles can be processed into<br></br>   polyester fibers, woven into fabric, reducing<br></br>  waste and promoting sustainability at Sun Knits.
</div>
          </div>
              
          </div>
           
          

         </div>

         <div className="w-[100%] h-[244px] flex flex-col mt-[244px]">
          <div className="gap-[112px] flex mt-[118px]">
            <div className="gradient-understa w-[971px] h-[414px] rounded-[55px]">
            <div className="w-[493px] h-[162px] mt-[57px] flex w-[89%] mx-auto ml-[242px]">
                <img src={organic} className="lazy"/>
                <div className="2xl:text-[26px] leading-[1.7rem] font-poppins font-medium text-[#9f9f9f] mt-[76px] ">Organic Cotton</div>
              </div> 
              <div className="text-center flex mx-auto w-[49%] text-white mt-[22px]">Sun Knits prioritises eco-friendliness by using organic cotton, reducing environmental impact and promoting recycling processes in the textile industry.</div>
            </div>
          {/* <div className="flex flex-col w-[50%] gradient-understa w-[421px] h-[391px] flex mx-auto rounded-[55px]">
            <div className="flex flex-row mt-[57px]">
              <div className="w-[376px] h-[164px] ml-[37px] flex">
                <img src={fishnets} />
                <div className="2xl:text-[26px] leading-[1.7rem] font-poppins font-medium text-[#9f9f9f] mt-[56px] ml-[12px]">fish nets</div>
              </div> 
            </div>
            <div className=" ml-[37px] font-poppins text-[13px] leading-[1.9rem] text-white mt-[25px] font-light ">Discarded fishing nets can be repurposed into<br></br> nylon fibers, woven into fabric, promoting<br></br> sustainability and reducing ocean pollution at Sun Knits.</div>
          </div>

          
          <div className="flex flex-col w-[50%] gradient-understa w-[421px] h-[391px] flex mx-auto rounded-[55px]">
            <div className="flex flex-row mt-[57px]">
              <div className="w-[376px] h-[164px] ml-[37px] flex">
                <img src={fishscale} />
                <div className="2xl:text-[26px] leading-[1.7rem] font-poppins font-medium text-[#9f9f9f] mt-[56px] ml-[12px]">fish scales</div>
              </div> 
            </div>
            <div className=" ml-[37px] font-poppins text-[13px] leading-[1.9rem] text-white mt-[25px] font-light ">Sun Knits upcycles fish scales into<br></br> premium-quality fabrics, offering sustainable<br></br> alternatives while reducing waste and environmental impact.</div>
          </div> */}
              
          </div>
           
          

         </div>
         
         </div>
    
         
         
       </div>
       </div>
       </div>
 
       <div className="2xl:hidden sm:hidden md:block h-[744px] bg-black flex flex-col">
         <div className="flex flex-row gap-[70px] pt-[32px]">
           <div className="flex flex-col w-[309px] h-[335px] gradient-understand rounded-[35px] ml-[44px] mt-[122px]">
            <div className="text-[16px] font-poppins font-light text-white mt-[43px] ml-[34px]">Leveraging</div>
            <div className="text-[50px] font-poppins font-medium leading-[3.4rem] tracking-custom gradient-recycle mt-[-4px] ml-[32px] ">Recycled Fabrics</div>
            <div className="text-white text-[13px] font-poppins font-normal leading-[1.8rem] mt-[19px] ml-[34px] w-full">
            At Sun Knits, we elevate sustainability by innovatively recycling waste into unique fabrics, expanding our eco-friendly product range while reducing environmental impact.
            </div>
           <div className='w-[195px] h-[1px] gradient-bdr mt-[-124px] ml-[324px] '></div>
           </div>
           <div className="flex flex-col w-[70%] h-[444px] ">
            <div className="flex flex-row gap-[44px] ml-[144px] ">
              <div className=" flex flex-col w-[328px] h-[297px] h-[144px] gradient-understa rounded-[35px]">
                <div className="flex flex-row">
                <img src={paper} className="lazy w-[119px] h-[110px] mt-[42px] ml-[33px] flex" />
               <div className=" text-[#9f9f9f] leading-[1.2rem] font-poopins text-[20px] font-medium ml-[12px] mt-[75px] flex ">Eco-friendly paper</div>
                </div>
                
                <div className="text-[12px] text-white w-full leading-[1.3rem] ml-[34px] mt-[14px]">
                Pulp from paper waste can be converted into fibers,<br></br> spun into yarn, and woven into fabric, offering<br></br> eco-friendly alternatives in apparel.
               </div>

              </div>
              <div className=" flex flex-col w-[328px] h-[297px] h-[144px] gradient-understa rounded-[35px]">
                <div className="flex flex-row">
                <img src={bttle} className="lazy w-[119px] h-[110px] mt-[42px] ml-[33px] flex" />
               <div className=" text-[#9f9f9f] leading-[1.2rem] font-poopins text-[20px] font-medium ml-[12px] mt-[75px] flex ">Used Plastic bottles</div>
                </div>
                
                <div className="text-[12px] text-white w-full leading-[1.3rem] ml-[34px] mt-[14px]">
                Recycled plastic bottles can be processed into <br></br> polyester fibers, woven into fabric, reducing<br></br>  waste and promoting sustainability at Sun Knits.
               </div>

              </div>

            </div>
            <div className="flex flex-row gap-[44px] ml-[144px] mt-[24px]">
            <div className="gradient-understa w-[741px] h-[214px] rounded-[55px]">
            <div className="w-[493px] h-[162px] mt-[57px] flex w-[89%] mx-auto ">
                <img src={organic} className="lazy w-[190px] h-[122px] ml-[122px]"/>
                <div className="md:text-[26px] leading-[1.7rem] font-poppins font-medium text-[#9f9f9f] mt-[46px] ">Organic Cotton</div>
              </div> 
              <div className="text-center flex mx-auto w-[49%] text-white mt-[22px] text-[14px] ">Sun Knits prioritises eco-friendliness by using organic cotton, reducing environmental impact and promoting recycling processes in the textile industry.</div>
            </div>
            </div>
           </div>
         </div>
       </div>

       




         

        <div className="bg-black ">
          <div className="h-[725px] w-[89%] flex mx-auto pt-[42px] rounded-[92px]" style={manbanner}>
            <div className="flex flex-col w-[60%] h-full relative overflow-hidden transition-transform transform scale-100 hover:scale-105 hover:translate-y-0.5">
              <img src={halfsleeve} className="lazy h-[684px] w-full object-contain" alt="Half Sleeve" />
            </div>
            <div className="flex flex-col w-[40%] h-full mt-[164px] ">
              <div className="font-poppins text-[40px] font-bold text-white">MEN's</div>
              <div className="font-poppins 2xl:text-[64px] md:text-[44px] text-[#6a6a6a] leading-[3.7rem]">Half Sleeve Graphic Washed Tee.</div>
              <Link to="/productsnew">
                <div className="text-[32px] font-light text-[#D65B03]">View</div></Link>
            </div>
          </div>
        </div>

        <div className="bg-black">
          <div className="h-[884px] w-[93%] mx-auto flex gap-[25px] ">

            <div className="flex gap-[25px] mt-[74px]">
              <div className="image-container1">
                <img src={tshirts1} className="lazy w-[582px] h-[666px]" />
                <div className="text-container">
                  <div className="font-bold text-[40px] text-white">Men's</div>
                  <div className="font-light text-[35px] text-[#898989]">Multi-Colour T-Shirt.</div>
                </div>
              </div>
              <div className="image-container2">
                <img src={tshirts2} className="lazy w-[582px] h-[666px]" />
                <div className="font-bold text-[40px] text-white">Men's</div>
                <div className="font-light text-[35px] text-[#898989]">Striped T-Shirt</div>
              </div>
              <div className="image-container3">
                <img src={tshirts3} className="lazy w-[582px] h-[666px]" />
                <div className="font-bold text-[40px] text-white">Men's</div>
                <div className="font-light text-[35px] text-[#898989]">Round Neck Graphic Tee</div>
              </div>
            </div>

          </div>

          <div className="h-[1198px] bg-black flex mx-auto w-[93%]">
            <div className="flex flex-col h-full bg-black ">
              <div className="content mt-[112px]">
                <div className="text-[32px] font-normal font-poppins text-[#858585] ">Lounge Wear</div>
                <div className="2xl:text-[95px] md:text-[73px] font-normal font-poppins text-[#f4f4f4] leading-[5.7rem]">High GSM Heavy <br></br> <span className="font-light">Co-Ords</span></div>
                <div className="text-[24px] font-light font-poppins text-[#f9f7f7] mt-[15px] w-[89%] leading-[2.5rem]">At Sun Knits, we fuse global trends with climate awareness, delivering fashionable yet functional garments just like this Heavy Hoody and Jogger pair over here, where we get everything tailored to your desires.</div>
                <div className="flex relative group w-[757px] h-[479px] mt-[44px]"  >
                  <img
                    src={pant1}
                    className="lazy w-full h-full object-cover transition-opacity duration-700 group-hover:opacity-0"
                  />
                  <img
                    src={pant2}
                    className="lazy w-full h-full object-cover opacity-0 transition-opacity duration-700 absolute top-0 left-0 group-hover:opacity-100"
                  />
                </div>
              </div>


            </div>
            <div className="flex relative group"  >
              <img
                src={hoodie}
                className="lazy w-[1952px] h-[1000px] object-cover transition-opacity duration-700 group-hover:opacity-0"
              />
              <img
                src={hoodie1}
                className="lazy w-[966px] h-[1000px] object-cover opacity-0 transition-opacity duration-700 absolute top-0 left-0 group-hover:opacity-100"
              />
            </div>
          </div>

          <div className="bg-[#0C0C0C] h-[1927px]">
            <div className="text-[48px] font-poppins font-medium text-[#f4f4f4] items-center justify-center flex pt-[74px]">Mastering Women's Fashion</div>
            <div className="text-[24px] font-poppins font-light text-[#f9f7f7] items-center justify-center flex">As masters in women's garments, we excel in fabric selection and unique pattern design.</div>
            <img src={best} />
            <div className="quality flex gap-[37px] mt-[74px] w-[93%] mx-auto">
              <div className="quality-container1">
                <img src={quality1} className="lazy w-[591px] h-[616px]" />
                <div className="font-bold text-[40px] text-white">Women's</div>
                <div className="font-light text-[24px] text-[#898989]">Bold Graphic Top</div>
              </div>

              <div className="quality-container2">
                <img src={quality2} className="lazy w-[591px] h-[616px]" />
                <div className="font-bold text-[40px] text-white">Women's</div>
                <div className="font-light text-[24px] text-[#898989]">Dress</div>
              </div>

              <div className="quality-container3">
                <img src={quality3} className="lazy w-[591px] h-[922px]" />
                <div className="font-bold text-[40px] text-white text-center">Women's</div>
                <div className="font-light text-[24px] text-[#898989] text-center">Floral Tunic Dress</div>
              </div>
            </div>
          </div>

          <div className="h-[2278px] bg-black w-[87%] mx-auto">
          <div className="flex relative group">
  {/* <img
    src={whatmakes}
    className="w-[972px] h-[907px] mt-[-324px] transition-opacity duration-300 group-hover:opacity-0"
  />
  <img
    src={secondImage}
    className="w-[972px] h-[907px] mt-[-324px] opacity-0 transition-opacity duration-300 absolute top-0 left-0 group-hover:opacity-100"
    style={{ zIndex: 1 }} 
  /> */}
<div className="w-[70%] mt-[-192px] border-black b-2 bg-black px-4 py-4 rounded-[49px]">
<div className="whatmakes-container5">
                <img src={whatmakes} className="lazy w-[971px] h-[907px]" />
                {/* <div className="font-bold text-[40px] text-white">Women</div>
                <div className="font-light text-[24px] text-[#898989]">Knitted wool Sweater</div> */}
              </div>
</div>


  <div className="text-[24px] font-poppins font-light text-white w-[55%] relative">
    <div className="2xl:text-[70px] md:text-[51px]  font-medium font-poppins text-[#DC7A01] leading-[4.7rem] 2xl:w-[92%] md:w-full mt-[194px] ml-[34px] tracking-customs2">
    Trims & Accessories<span className="text-white font-extralight"> Focus.</span>
    </div>
    <p className="ml-[34px] mt-[29px] leading-[2.8rem]">At Sun Knits, we prioritise intricate detailing, importing unique trims and accessories from countries like China and Indonesia to ensure our apparel stands out with exceptional craftsmanship.</p>
  </div>
</div>
            <div className="flex gap-[37px] mt-[124px] ">
              <div className="w-[552px] h-[629px] relative sweater1" >
                <div className="2xl:mt-[-10px]" style={textContainer as React.CSSProperties}>
                  <div className="text-white font-bold text-2xl">Baby’s</div>
                  <div className="text-white">Button up Comforter.</div>
                </div>
              </div>

              <div className="w-[552px] h-[629px] relative sweater2" >
                <div style={textContainer as React.CSSProperties}>
                  <div className="text-white font-bold text-2xl">Boy's</div>
                  <div className="text-white">Graphic T-Shirt</div>
                </div>
              </div>
              <div className="w-[552px] h-[629px] relative sweater3" >
                <div style={textContainer as React.CSSProperties}>
                  <div className="text-white font-bold text-2xl">Girl's</div>
                  <div className="text-white">Graphic Round Neck tee</div>
                </div>
              </div>

            </div>


            <div className="flex gap-[37px] mt-[124px] ">
              <div className="w-[552px] h-[629px] relative sweater4" >
                <div style={textContainer as React.CSSProperties}>
                  <div className="text-white font-bold text-2xl">Baby’s</div>
                  <div className="text-white">Romber.</div>
                </div>
              </div>

              <div className="w-[552px] h-[629px] relative sweater5" >
                <div style={textContainer as React.CSSProperties}>
                  <div className="text-white font-bold text-2xl">Girl's</div>
                  <div className="text-white">Pink Stripe T-Shirt</div>
                </div>
              </div>
              <div className="w-[552px] h-[629px] relative sweater6" >
                <div style={textContainer as React.CSSProperties}>
                  <div className="text-white font-bold text-2xl">Boy's</div>
                  <div className="text-white">Polo T-Shirt</div>
                </div>
              </div>

            </div>
{/* 
            <div className=" h-[344px] flex gap-[37px] mt-[124px] ">
              <div className="w-[552px] h-[629px] relative sweater4">
                <div style={textContainer as React.CSSProperties}>
                  <div className="text-white font-bold text-2xl">Sweater</div>
                  <div className="text-white">Lorem Ipsum Dolor</div>
                </div>
              </div>
              <div className="w-[552px] h-[629px] relative sweater5" >
                <div style={textContainer as React.CSSProperties}>
                  <div className="text-white font-bold text-2xl">Sweater</div>
                  <div className="text-white">Lorem Ipsum Dolor</div>
                </div>
              </div>
              <div className="w-[552px] h-[629px] relative sweater6" >
                <div style={textContainer as React.CSSProperties}>
                  <div className="text-white font-bold text-2xl">Sweater</div>
                  <div className="text-white">Lorem Ipsum Dolor</div>
                </div>
              </div>

            </div>
 */}

          </div>
          <div className="h-[1081px] " style={fittframe}>
            <div className="font-poppins font-semibold 2xl:text-[60px] 2xl:w-[80%] md:text-[45px] text-[#BCA493] pl-[100px] pt-[245px] leading-[3.9rem]">Slim-Fit Hoodie:</div>
            <div className="font-poppins 2xl:text-[55px] md:text-[35px] text-white font-semibold pl-[100px]">Fit<span className="font-poppins 2xl:text-[55px] md:text-[35px] text-white font-normal"> Accuracy Ensured.</span> </div>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </div>

      <div className="2xl:hidden md:hidden sm:block">
        <MobilePrdouct/>
      </div>
       



    </div>

  );
}