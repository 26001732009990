import React from "react";
import { NavBar1 } from "../Components/navBar1";
import fabrics from '../../Component Library/productbanner.png'
import halfsleeve from '../../Component Library/Halfsleve.png'
import tshirts1 from '../../Component Library/t-shirts-1.png'
import tshirts2 from '../../Component Library/t-shirts-2.png'
import tshirts3 from '../../Component Library/t-shirts-3.png'
import demonstrateframe from '../../Component Library/demonstrate-frame.png'
import demonstrateframe1 from '../../Component Library/demonstrate-frame1.png'
import best from '../../Component Library/bestinquality.png'
import quality1 from '../../Component Library/quality1.png'
import quality2 from '../../Component Library/quality2.png'
import quality3 from '../../Component Library/quality3.png'
import whatmakes from '../../Component Library/WhatMakes1.png'
import frame1 from '../../Component Library/frame1.png'
import frame2 from '../../Component Library/frame2.png'
import frame3 from '../../Component Library/frame3.png'
import frame4 from '../../Component Library/svg/frame4.png'
import frame5 from '../../Component Library/svg/frame5.png'
import frame6 from '../../Component Library/svg/frame6.png'
import frame from '../../Component Library/fittdsgnbg.png'
import frameimg from '../../Component Library/fittdesign.png'
import secondImage from '../../Component Library/WhatMakes2.png'
import hoodie from '../../Component Library/svg/hoodie2.png'
import hoodie1 from '../../Component Library/svg/hoodie1.png'
import pant1 from '../../Component Library/svg/pant1.png'
import pant2 from '../../Component Library/svg/pant2.png'
import manbg from '../../Component Library/menbannerdesktop.png'
import mbbanner from '../../Component Library/product_hero.png'
import hoodiephone from '../../Component Library/svg/hoodie1phone.png'
import mbpant from '../../Component Library/svg/mbpant1.png'
import womenmb1 from '../../Component Library/svg/womenmb-1.png'
import womenmb2 from '../../Component Library/svg/womenmb-2.png'
import womenmb3 from '../../Component Library/svg/womenmb-3.png'
import fittdesign from '../../Component Library/svg/mblast.jpg'
import whatmakes1 from '../../Component Library/product_yellow.png'
import paper from '../../Component Library/paperdk.svg'
import bttle from '../../Component Library/bottledk.png'
import fishnets from '../../Component Library/fishnetdk.png'
import fishscale from '../../Component Library/fishscalesdk.png'
import organic from '../../Component Library/organiccotton.png'
import { useEffect } from "react";
import LazyLoad from 'vanilla-lazyload';
import { Link } from "react-router-dom";
import Footer from "../Components/footer";
import { FooterMobile } from "../Components/fotterMobile";
import MobileNavbar from "../Components/navbarMobile";
import MobileNavbar2 from "../Components/productMobile";

const MobilePrdouct = () => {
    const lastsection = {
        backgroundImage: `url(${fittdesign})`,
    
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      };
    
  return (
    <div>
            {/* mobile view start*/}
            <div className="xl:hidden sm:block bg-black">
      <div>
            <MobileNavbar2/>
           </div>
      <div className="w-[97%] mx-auto h-[498px] bg-[#0f0f0f] rounded-[23px]">
        <div className="font-bold text-[24px] font-poppins text-center text-white pt-[32px]">Knit & Weave.</div>
        <div className="text-white text-center text-[14px] font-noraml w-full mx-auto mt-[7px]">We create an Extensive collection of Fabrics for<br></br> Kids, Ladies and Men's
          <img src={mbbanner} className="lazy mt-[32px] w-[74%] mx-auto" />
        </div>
      </div>


      <div className="sm:block bg-black h-[1294px] w-full">
        <div className="pt-[44px] w-[88%] mx-auto flex item-center justify-center">
        <div className="w-[340px] h-[251px] gradient-understand rounded-[26px]">
          <div className="text-center font-poppins text-white text-[12px] font-light mt-[36px] ml-[37px] mr-[37px] ">Leveraging</div>
          <div className="font-medium font-poppins text-[30px] gradient-recycle text-center">Recycled Fabrics</div>
          <div className="text-[10px] font-normal font-poppins text-center leading-[1.4rem] text-white w-[84%] mx-auto mt-[17px]">At Sun Knits, we elevate sustainability by innovatively recycling waste into unique fabrics, expanding our eco-friendly product range while reducing environmental impact.</div>
          <div className='w-[1px] h-[75px]  gradient-bdrs mt-[19px] ml-[172px]'></div>
          <div className="w-full h-[209px] gradient-understa rounded-[26px] mt-[21px] ">
          <div className="flex flex-row">
                <img src={paper} className="w-[75px] h-[70px] mt-[42px] ml-[57px] flex" />
               <div className=" text-[#9f9f9f] leading-[1.2rem] font-poppins text-[20px] font-medium ml-[22px] mt-[59px] flex ">Eco-friendly<br></br> paper</div>
                </div>
                 
                 <div className="text-white font-light font-poppins text-[10px] leading-[1.2rem] w-[84%] mx-auto text-center mt-[19px]">Pulp from paper waste can be converted into fibers, spun into yarn, and woven into fabric, offering eco-friendly alternatives in apparel.
</div>

         </div>

         <div className="w-full h-[209px] gradient-understa rounded-[26px] mt-[21px] ">
          <div className="flex flex-row">
                <img src={bttle} className="w-[75px] h-[70px] mt-[42px] ml-[57px] flex" />
               <div className=" text-[#9f9f9f] leading-[1.2rem] font-poppins text-[20px] font-medium ml-[22px] mt-[59px] flex ">Used Plastic<br></br>bottles</div>
                </div>
                 
                 <div className="text-white font-light font-poppins text-[10px] leading-[1.2rem] w-[84%] mx-auto text-center mt-[19px]">Recycled plastic bottles can be processed into polyester fibers, woven into fabric, reducing waste and promoting sustainability at Sun Knits.
</div>

         </div>

         <div className="w-[341px] h-[229px] gradient-understa rounded-[26px] mt-[21px] ">
          <div className="flex flex-row">
                <img src={organic} className="w-[75px] h-[70px] mt-[42px] ml-[57px] flex" />
               <div className=" text-[#9f9f9f] leading-[1.2rem] font-poppins text-[20px] font-medium ml-[22px] mt-[59px] flex ">Organic Cotton</div>
                </div>
                 
                 <div className="text-white font-light font-poppins text-[10px] leading-[1.2rem] w-[84%] mx-auto text-center mt-[19px]">Discarded fishing nets can be repurposed into nylon fibers, woven into fabric, promoting sustainability and reducing ocean pollution at Sun Knits.</div>

         </div>
        

        </div>
         
         
         
          
          </div>
       </div>




      <div className="flex mt-[-214px] h-[153px] w-[97%] mx-auto ">
            <div className="bg-[#6A6A6A ] flex" >
              <div className="flex flex-col w-[50%] h-full ml-[22px]">
                <img src={halfsleeve} className="w-[452px] h-[144px]" alt="Half Sleeve" />

              </div>
              <div className="flex flex-col w-[50%] h-full pt-[54px]">
                <div className="text-white text-[15px] text-left font-poppins ">Men</div>
                <div className="text-[10px] font-poppins text-white text-left">Half Sleeve Graphic Washed Tee.</div>
                <Link to="/productsnew">
                  <div className="text-[12px] font-light text-[#D65B03] text-left">View</div></Link>
              </div>
            </div>
          </div>


      <div className="bg-black mx-auto mt-[-2px]">
      <div className="flex w-[98%] mx-auto bg-black  h-[244px] gap-[10px]">
           <div className="flex flex-col w-[33.3%]  ">
           <img src={tshirts1} className="w-[110px] h-[176px]" />
                <div className="text-container">
                  <div className="font-bold text-[20px] text-white">Men's</div>
                  <div className="font-light text-[12px] text-[#898989]">Multi-Colour T-Shirt.</div>
                </div>
           </div>
           <div className="flex flex-col w-[33.3%]   ">
           <img src={tshirts2} className="w-[110px] h-[176px]" />
                <div className="text-container">
                  <div className="font-bold text-[20px] text-white">Men's</div>
                  <div className="font-light text-[12px] text-[#898989]">Pin Striped T-Shirt</div>
                </div>
           </div>
           <div className="flex flex-col w-[33.3%]  ">
           <img src={tshirts3} className="w-[110px] h-[176px]" />
                <div className="text-container">
                  <div className="font-bold text-[20px] text-white">Men's</div>
                  <div className="font-light text-[12px] text-[#898989]">Round Neck Graphic Tee</div>
                </div>
           </div>

      </div>
      </div>
      <div className="raw-materials bg-black pt-[24px]">
          <div className="text-[16px] font-poppins font-normal text-left text-[#858585] ml-[21px]">Lounge Wear</div>
          <div className="text-[24px] font-normal font-poppins text-[#f4f4f4] leading-[1.7rem] ml-[21px]">High GSM <span className="font-light">Heavy Co-Ords</span></div>
                <div className="text-[12px] font-light font-poppins text-[#f9f7f7] mt-[15px] w-[89%] leading-[1.5rem] ml-[21px]">At Sun Knits, we fuse global trends with climate awareness, delivering fashionable yet functional garments just like this Heavy Hoody and Jogger pair over here, where we get everything tailored to your desires. </div>
                <img src={hoodiephone} className="mx-auto mt-[14px]" alt="Half Sleeve" />
                <img src={mbpant} className="mx-auto mt-[14px]" alt="Half Sleeve" />

      </div>
      <div className="best bg-black mt-[22px]">
      <div className="text-[24px] font-poppins font-medium text-[#f4f4f4] items-center justify-center flex mt-[44px]">Mastering Women's Fashion</div>
            <div className="text-[14px] font-poppins font-light text-[#f9f7f7] items-center justify-center flex w-[78%] mx-auto text-center">As masters in women's garments, we excel in fabric selection and unique pattern design.</div>
            <img src={best} />
      </div>

      <div className="women bg-[#0C0C0C]">
          <div className="flex w-[97%] mx-auto gap-[11px] mt-[21px]">
            <div className="flex flex-col w-[50%] h-[311px]">
            <img src={womenmb1} />
            <div className="text-[10px] font-poppins text-white ">Women's</div>
            <div className="text-[8px] font-light font-poppins  text-[#898989]">Bold Graphic Top</div>
            </div>
            <div className="flex flex-col w-[50%] h-[311px]">
            <img src={womenmb2} />
            <div className="text-[10px] font-poppins text-white ">Women's</div>
            <div className="text-[8px] font-light font-poppins  text-[#898989]">Dress</div>
            </div>
          </div>
          <div className="flex w-[97%] mx-auto items-center justify-center gap-[11px] mt-[-21px]"> <img src={womenmb3} /></div>
          <div className="text-[13px] font-poppins text-white text-center">Women's</div>
            <div className="text-[10px] font-light font-poppins text-center  text-[#898989]">Floral Tunic Dress</div>
            <div className="text-[24px] font-poppins font-light text-white w-full">
                <div className="text-[22px] font-bold font-poppins text-[#DC7A01] leading-[1.7rem] w-[72%] mt-[74px] ml-[24px]">
                Trims & Accessories<br></br> <span className="text-white font-light">Focus.</span>
                </div>
                <p className="ml-[24px] text-[12px] leading-[1.4rem] w-[95%]">At Sun Knits, we prioritise intricate detailing, importing unique trims and accessories from countries like China and Indonesia to ensure our apparel stands out with exceptional craftsmanship.</p>
                <img src={whatmakes1} className="w-full" />

                
                <div className="swater mt-[32px]">
  <div className="bg-black mx-auto">
    <div className="flex w-[98%] mx-auto bg-black h-[244px] gap-[10px]">
      <div className="flex flex-col w-[33.3%] relative">
        <img src={frame1} className="w-[110px] h-[176px]" />
        <div className="text-container absolute bottom-0 left-0">
          <div className="font-bold text-[10px] text-white mt-[-117px]">Baby’s</div>
          <div className="font-light text-[8px] text-[#898989]">Button up Comforter.</div>
        </div>
      </div>
      <div className="flex flex-col w-[33.3%] relative">
        <img src={frame2} className="w-[110px] h-[176px]" />
        <div className="text-container absolute bottom-0 left-0">
          <div className="font-bold text-[10px] text-white mt-[-117px]">Boy's</div>
          <div className="font-light text-[8px] text-[#898989]">Graphic T-Shirt</div>
        </div>  
      </div>
      <div className="flex flex-col w-[33.3%] relative">
        <img src={frame3} className="w-[110px] h-[176px]" />
        <div className="text-container absolute bottom-0 left-0">
          <div className="font-bold text-[10px] text-white  mt-[-117px]">Girl's</div>
          <div className="font-light text-[8px] text-[#898989]">Graphic Round Neck tee</div>
        </div>
      </div>
    </div>
  </div>


  <div className="bg-black mx-auto">
    <div className="flex w-[98%] mx-auto bg-black h-[244px] gap-[10px]">
      <div className="flex flex-col w-[33.3%] relative">
        <img src={frame4} className="w-[110px] h-[176px]" />
        <div className="text-container absolute bottom-0 left-0">
          <div className="font-bold text-[20px] text-white mt-[-117px]">Baby’s</div>
          <div className="font-light text-[12px] text-[#898989]">Romber.</div>
        </div>
      </div>
      <div className="flex flex-col w-[33.3%] relative">
        <img src={frame5} className="w-[110px] h-[176px]" />
        <div className="text-container absolute bottom-0 left-0">
          <div className="font-bold text-[20px] text-white mt-[-117px]">Girl's</div>
          <div className="font-light text-[12px] text-[#898989]">Pink Stripe T-Shirt</div>
        </div>  
      </div>
      <div className="flex flex-col w-[33.3%] relative">
        <img src={frame6} className="w-[110px] h-[176px]" />
        <div className="text-container absolute bottom-0 left-0">
          <div className="font-bold text-[20px] text-white  mt-[-117px]">Boy's</div>
          <div className="font-light text-[12px] text-[#898989]">Polo T-Shirt</div>
        </div>
      </div>
    </div>
  </div>
</div>
               
              </div>
      </div>
   
     <div className="lastsection h-[749px] w-full" style={lastsection}>
     <div className="text-[24px] font-semobold font-poppins text-[#BCA493] pt-[346px] ml-[24px]">Slim-Fit Hoodie:</div> 
     <div className="text-[15px] font-bold font-poppins text-white leading-[2.7rem] w-[72%] ml-[24px]">Fit Accuracy <span className="text-[#767676] font-light">Ensured.</span> </div>
     </div>
       
       <div>
       <FooterMobile/>
       </div>
     </div>
      {/* mobile view end*/}
    </div>
  );
};

export default MobilePrdouct;