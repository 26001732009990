import React from "react";
import SecondPage from "./secondPage";
import Infrahome from "./infrahome";
import FouthPage from "./FourthPage";
import OurProducts from "./oruProducts";
import Sustainability from "./Sustain";
import ContactMain from "./contactus";
import LandingPage12 from "./landing";


export const Home = () => {
  return (
  <div>
    <LandingPage12/>
    <SecondPage/>
    <Infrahome/>
    <FouthPage/>
    <OurProducts/>
    <Sustainability/>
    <ContactMain/>
  </div>
  );
};