import React from "react";
import solar from '../../Component Library/Solarsustain.png'
import solarmb from '../../Component Library/svg/SolarPanelmb.png';
import whitebg from '../../Component Library/white gradient.png';
import whitebg1 from '../../Component Library/rectangle_299.png';
export const SustainabilityComponentTwo=()=>{
    const backgroundStyle = {
        backgroundImage: `url(${solar})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '788px',
        height:'671px'
      };
    
    return(

   <div>
    <div className="desktop hidden xl:block">
        <div>
            <img src={whitebg} className="mt-[-212px]"/>
        </div>
    <div className="flex flex-col w-screen h-screen my-[118px] mt-[-82px] ">
        <div className="flex w-[100%] h-[75%] mx-[80px]">
            <div className="flex 2xl:w-[60%] md:w-[30%] 2xl:h-[90%] md:h-[40%] rounded-[54px]" style={backgroundStyle}>

            </div>
            <div className="flex 2xl:w-[50%] md:w-[70%] h-[75%] my-4 mx-[12px]">
                <div className="w-full h-[20%] text-left flex-col ml-[32px] mt-[82px] tracking-customs ">
                    <div className="2xl:text-[64px] md:text-[47px] font-bold font-poppins leading-[2.4rem]">Solar Initiative</div>
                    <div className="2xl:text-[32px] md:text-[22px]  font-semibold font-poppins mt-[22px]">Illuminating Tomorrow's Textile Industry Today:</div>
                    <div className="2xl:text-[24px] md:text-[18px] font-normal my-[24px] font-poppins mt-[34px] 2xl:w-[94%] md:w-[84%] leading-[2.5rem]">At Sun Knits, we champion environmental responsibility by adopting solar panels as a primary energy source for our factory. This strategic move not only aligns with our commitment to reducing carbon footprints but also reflects our dedication to renewable energy solutions. By harnessing the power of the sun, we not only contribute to a cleaner environment but also set a sustainable precedent for the textile industry, prioritising eco-conscious practices in every facet of our operations.</div>
                   
                    </div>
                {/* <div className="w-full h-[20%]">Illuminating Tomorrow's Textile Industry Today:</div> */}
            </div>

        </div>


    </div>
    </div>

    <div className="mobile xl:hidden sm:block">
    <div>
            <img src={whitebg1} className="mt-[-52px]"/>
        </div>
       <div className="text-[24px] font-poppins font-bold text-black text-center mx-auto pt-[21px] mt-[-74px]">Solar</div>
       <div className="text-[12px] font-poppins font-medium text-center w-[89%] mx-auto">Illuminating Tomorrow's Textile Industry Today:</div>
       <img className='pt-[43px] mx-auto mt-[-34px]'src={solarmb} />
       <div className="text-[14px] font-normal leading-[1.4rem] text-black font-poppins w-[89%] mx-auto mt-[15px]">At Sun Knits, we champion environmental responsibility by adopting solar panels as a primary energy source for our factory. This strategic move not only aligns with our commitment to reducing carbon footprints but also reflects our dedication to renewable energy solutions. By harnessing the power of the sun, we not only contribute to a cleaner environment but also set a sustainable precedent for the textile industry, prioritising eco-conscious practices in every facet of our operations.</div>
    </div>
    </div>
    )
}