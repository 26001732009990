
import React from 'react'
import fblogo from '../../Component Library/fblogo.svg'
import linkedinlogo from '../../Component Library/linkedinlogo.svg'
import youtubelogo from '../../Component Library/youtube.svg'
import sunknitLogo from '../../Component Library/footerlogo.svg';
import { Link } from "react-router-dom";


export const FooterMobile = () => {

    return (
        <div className='footer'>
           <div className='xl:hidden sm:block w-full'>
        <div className='footer w-[99%] h-[226px] bg-black mx-auto mt-[34px] flex '>
          <div className='flex flex-col w-[50%] bg-black h-[144px] ml-[42px] mt-[32px] pt-[24px]'>
            <div className='font-normal text-white font-poppins text-[16px] pb-[14px]'>
              <Link to="/">
                Home
              </Link>
            </div>
            <div className='font-normal text-white font-poppins text-[16px] pb-[14px]'>
              <Link to="/about">About us</Link>

            </div>
            <div className='font-normal text-white font-poppins text-[16px] pb-[14px]'>
              <Link to="/infrastructure">Infrastructure</Link>

            </div>
            <div className='font-normal text-white font-poppins text-[16px]'>
              <Link to="/products">
                Products
              </Link>
            </div>
          </div>
          <div className='flex flex-col w-[50%] bg-black h-[144px] mt-[32px] pt-[24px]'>
            <div className='font-normal text-white font-poppins text-[16px] mb-[14px]'>
              <Link to="/sustainability">
                Sustainbility
              </Link>
            </div>

            <div className='font-normal text-white font-poppins text-[16px]'>
            <Link to="/contact">Contact us</Link>
            </div>
          </div>
        </div>
        <div className='bg-black w-[99%] mx-auto h-[544px] mt-[-34px]'>
          <div className='w-[50%] flex mx-auto'> <img src={sunknitLogo} className='h-[174px]' /></div>
          <div className='text-center text-white text-[15px] leading-[1.8rem] mt-[-32px] font-poppins font-light'>S.F.NO: 250/1,</div>

          <div className='text-center text-white text-[15px] leading-[1.8rem] font-poppins font-light'>Sri Kanchi Kamatchi Nagar,</div>

          <div className='text-center text-white text-[15px] leading-[1.8rem] font-poppins font-light'>Kangayampalayam Pudur,Kangayam Road,</div>

          <div className='text-center text-white text-[15px] font-poppins font-light'>Tirupur - 641604</div>

          <div className='h-[0.5px] w-[70%] bg-[#333] mt-[28px] mx-auto'></div>

          <div className='text-[17px] text-white font-medium text-center mt-[24px] font-poppins font-light'>Follow Us</div>
          <div className='flex flex-row gap-[15px] items-center justify-center mt-[24px]'>
            <img src={fblogo} className='w-[25px] h-[25px]'></img>
            <img src={linkedinlogo} className='w-[25px] h-[25px]'></img>
            <img src={youtubelogo} className='w-[25px] h-[25px]'></img>
          </div>
          <div className='text-[10px]  text-white text-center mt-[24px] font-poppins font-light'>© 2024,Sunknit Fashions . All Rights Reserved</div>

        </div>
      </div>
        </div>
        );
    };
    