import React from 'react';
import ImageSlideshow from './fading';
import ImageSlider from './imageSlider';
import bannermb from '../../Component Library/svg/inframobile.jpg'
import arowlanding from '../../Component Library/arrow-landing.svg'

const Infrahome = () => {

  const inframb = {
    backgroundImage: `url(${bannermb})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };
  return (
  <div className='flex sm:flex-col xl:flex-row h-[948px] w-screen w-[85%] mx-auto gap-[20px] xl:mt-[124px] sm:mt-[1324px] px-4'>
  {/* Desktop view */}
  <div className='flex flex-col w-full sm:w-[100%] bg-[#FFF1E7] rounded-[34px] gap-[10px] lg:h-[958px] 2xl:mt-[18px] md:mt-[498px]  hidden xl:block '>
    <div className='infra xl:ml-[34px] sm:ml-[12px] mt-[48px]'>
      <div className='xl:text-[24px] sm:text-[18px] font-bold text-[#D65B03] font-poppins sm:text-center xl:text-left'>INFRASTRUCTURE</div>
      <div className='xl:text-[41px] sm:text-[17px] xl:text-left sm:text-center font-semibold text-[#252525] font-poppins leading-tight mt-[15px] tracking-customs'>Precision, Safety and Efficiency for World-Class Garment Production</div>
      <div className='xl:text-[22px] sm:text-[15px] xl:text-left sm:text-center font-light text-[#33333] font-poppins w-[97%] mt-[8px] '>Sun Knit Infrastructure epitomises precision, meeting every requisite for crafting world-class garments. Beyond production, our facilities prioritise safety, boasting dedicated spaces for client consultations and hands-on experiences. Committed to efficiency, our organised approach includes a proficient IT team ensuring seamless operations throughout the factory. Experience excellence in every aspect – at Sun Knit Fashions</div>
      <ImageSlider />
    </div>
  </div>
  <div className='flex flex-col w-full sm:w-[100%]  gap-[10px] hidden xl:block sm:hidden  2xl:mt-[18px] md:mt-[498px]  '>
    <ImageSlideshow />
  </div>
  {/* Desktop view end */}

   
{/* Mobile view start*/}
   <div className='xl:hidden sm:block  '>
   <div className='flex flex-col  bg-[#FFF1E7] h-[711px] rounded-[34px] gap-[10px]  xl:mt-[8px]  sm:mt-[-818px] '>
    <div className='infra xl:ml-[34px] sm:ml-[12px] mt-[21px]'>
      <div className='xl:text-[24px] sm:text-[18px] font-bold text-[#D65B03] font-poppins sm:text-center xl:text-left '>INFRASTRUCTURE</div>
      <div className='xl:text-[41px] sm:text-[17px] xl:text-left sm:text-center font-semibold text-[#252525] font-poppins leading-[1.7rem] mt-[15px] tracking-customs'>Precision, Safety and Efficiency for World-<br></br>Class Garment Production</div>
      <div className='xl:text-[22px] sm:text-[12px] xl:text-left sm:text-center font-normal text-[#33333] font-poppins w-[97%] mt-[8px] leading-[1.5rem]'>Sun Knit Infrastructure epitomises precision, meeting every requisite for crafting world-class garments. Beyond production, our facilities prioritise safety, boasting dedicated spaces for client consultations and hands-on experiences. Committed to efficiency, our organised approach includes a proficient IT team ensuring seamless operations throughout the factory. Experience excellence in every aspect – at Sun Knit Fashions</div>
       <div className='mt-[-22px] ml-[-12px]'><ImageSlider /></div>
    </div>
  </div>

  <div className="mobile h-[407px] hidden xl:hidden sm:block mt-[25px] rounded-[24px] relative" style={inframb}>
                     <div className='text-[20px] font-bold font-causten text-white tracking-customs4' style={{ position: 'absolute', top: 320, left: 20 }}>Our Infrastructure<span><img src={arowlanding} height='18px' width='17px' className='mt-[-22px] ml-[167px]'/> </span></div>
                     <div className='text-[14px] text-white font-poppins font-light' style={{ position: 'absolute', top: 350, left: 20 }}>Explore our state-of-the-art infrastructure <br></br> through captivating visuals.</div>
                    </div> 
   </div>
    
 
{/* Mobile view end*/}



 
</div>


  
  );
};

export default Infrahome;
