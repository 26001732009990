import React, { useRef } from 'react';
import user from '../../Component Library/svg/user.svg';
import email from '../../Component Library/svg/email.svg';
import phone from '../../Component Library/svg/phone.svg';
import message from '../../Component Library/svg/mess.svg';
import emailjs from '@emailjs/browser';

const ContactForm2 = () => {

  const form = useRef<HTMLFormElement>(null);
  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); 
    // Your email sending logic here
    if (form.current) {
      emailjs.sendForm('service_j91lhpf', 'template_hhpqre3', form.current, '_pVfs4jxe4DVKU6mU');
      form.current.reset(); // Reset the form
    } else {
      console.error('Form ref is null');
    }
  };
  
  return (
    <div className='desktop'>
    <div className="flex justify-center items-center h-[946px] rounded-[22px] mt-[48px]">
      <form ref={form} onSubmit={sendEmail} className="bg-white p-8  2xl:w-[758px] md:w-[598px]  h-[946px] relative rounded-[22px] border border-red">
        <div className="mb-[124px] relative flex items-center justify-center mt-[73px]">
          <input
            type="text"
            id="name"
            name="to_name"
            placeholder="Name"
            className="w-[75%] border-b border-[#CaCaCa] focus:outline-none focus:border-orange-500 px-2 py-1 w-[60%] text-[24px] font-poppins"
          />
          <span className="icon"> <img src={user} alt="user-icon" className="mr-2 ml-[-17px] h-[30px] w-[29px] " /></span>
        </div>

        <div className="mb-[124px] relative flex items-center justify-center">
          <input
            type="email"
            id="email"
            name="to_email"
            placeholder="Email"
            className="w-[75%] border-b border-gray-300 focus:outline-none focus:border-orange-500 px-2 py-1 w-[60%] text-[24px] font-poppins"
          />
          <span className="icon"><img src={email} alt="email-icon" className="mr-2 ml-[-17px] h-[30px] w-[39px] " /></span>
        </div>

        <div className="mb-[124px] relative flex items-center justify-center">
          <input
            type="tel"
            id="mobile"
            name="to_phone"
            placeholder="Phone Number"
            className="w-[75%] border-b border-gray-300 focus:outline-none focus:border-orange-500 px-2 py-1 w-[60%] text-[24px] font-poppins"
          />
          <span className="icon"><img src={phone} alt="phone-icon" className="mr-2 ml-[-17px] h-[30px] w-[30px] " /></span>
        </div>

        <div className="mb-[124px] relative flex items-center justify-center">
          <textarea
            id="message"
            name="message"
            placeholder="Message"
            className="w-[75%] border-b border-gray-300 focus:outline-none focus:border-orange-500 px-2 py-1 w-[60%] text-[24px] font-poppins"
          ></textarea>
          <span className="icon"><img src={message} alt="message-icon" className="mr-2 ml-[-17px] h-[28px] w-[26px] " /></span>
        </div>
        <button
        
  type="submit"
  className="border border-black px-[38px] py-[8px] rounded-[32px] focus:outline-none 2xl:ml-[64px] md:ml-[64px] 2xl:pl-[34px]  text-[24px] font-normal font-poppins  2xl:w-[158px] 2xl:h-[53px] "
>
  Submit
</button>
      </form>
    </div>
  

   




    </div>
  );
};

export default ContactForm2;