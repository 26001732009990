import { useState } from 'react';
import image1 from '../../Component Library/Fairwearsec1.jpg';
import image2 from '../../Component Library/Fairwearsec2.png';
import image3 from '../../Component Library/Fairwearsec3.png';
import arrow from '../../Component Library/bottomarow.png'
const tabsData = [
  {
    label: 'Environment',
    title: 'Environmental',
    introText: 'Wellness',
    content: 'Sun Knit places a high priority on the environment, air and water quality, and stressor reduction (e.g., greenhouse gas emissions). Keeping up biodiversity ensures that natural resources will always be available, which improves human health and promotes environmental sustainability.    ',
    backgroundImage: `url(${image1})`,
  },
  {
    label: 'Social',
    title: 'Social',
    introText: 'Welfare',
    content: 'Sun Knit encourages social sustainability, which includes resource security, health, education and justice for the environment. Achieving a harmonic balance between social, economic and environmental objectives can be facilitated by placing a high priority on employee well-being.',
    backgroundImage: `url(${image2})`,
  },
  {
    label: 'Economic',
    title: 'Economic',
    introText: 'Awareness',
    content: 'By fostering employment growth, profitability and ethical ecological service accounting, we advance economic sustainability. Sun Knit understands that by lowering operational costs and reducing the intensity of resource extraction, environmental sustainability initiatives like recycling can improve economic sustainability.',
    backgroundImage: `url(${image3})`,
  },
];

export function TabsNew() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <div>
      <div className="flex space-x-4 justify-center items-center">
        {/* Loop through tab data and render button for each. */}
        {tabsData.map((tab, idx) => (
       <button
       key={idx}
       className={`py-2 px-8 transition-colors duration-600 relative flex items-center ${idx === activeTabIndex
         ? 'text-orange-500' // Change to orange for the active tab
         : 'text-black hover:text-gray-600'
       } ${idx !== tabsData.length - 1 ? 'border-r-2 h-[35px]' : ''}`}
       onClick={() => setActiveTabIndex(idx)}
       style={{ marginRight: '8px',paddingLeft:'8px' }}
     >
            <span className="relative z-10 text-[32px] font-medium font-poppins capitalize ">{tab.label.toUpperCase()}</span>

            {/* Bottom arrow icon for active tab */}
            {idx === activeTabIndex && (
              <div
                className="absolute bottom-0 left-1/2 transform -translate-x-1/2 -mb-7 flex items-center"
                style={{
                  transitionProperty: 'left',
                  transitionTimingFunction: 'ease-in-out',
                  left: '50%',
                  transitionDuration: '1s',
                }}
              >
                <div className='w-7 h-7'>
                  <img src={arrow} className='w-full h-full' alt="Arrow" />
                </div>
              </div>
            )}
          </button>
        ))}
      </div>
      {/* Show active tab content with background image and centered content. */}
      <div
        className={`py-4 bg-cover bg-center mt-[44px] 2xl:w-[79%] md:w-[89%]   text-center mx-auto rounded-[78px]`}
        style={{ backgroundImage: tabsData[activeTabIndex].backgroundImage, minHeight: '681px' }}
      >
        <div className={`text-white text-left   ${tabsData[activeTabIndex].label === 'Environment' ? 'environment-content' : ''}`}>
          <h2 className="2xl:text-[64px] md:text-[32px] font-poppins text-[#fffff] font-semibold leading-[55px] ml-[94px] mt-[134px] tracking-custom">{tabsData[activeTabIndex].title}</h2>
          <p className="text-[48px] font-poppins text-[#fffff] font-normal leading-[55px] ml-[94px]">{tabsData[activeTabIndex].introText}</p>
          <p className='text-[22px] font-poppins text-[#fffff] font-light leading-[41px] w-[49%] ml-[94px] mt-[44px]'>{tabsData[activeTabIndex].content}</p>
        </div>
      </div>
    </div>
  );
}
