import React, { useState } from 'react';
import styled from 'styled-components';
import handShakeImage from '../../Component Library/handsake.png';
import industryImage from '../../Component Library/industryMastery.svg';
import personImage from '../../Component Library/personStar.svg';
import soulfulCraftsmanshipImage from '../../Component Library/soul.svg';

const Tab = styled.button`
  font-size: 25px;
  padding: 80px 60px;
  cursor: pointer;
  opacity: 0.6;
  background: white;
  border: 0;
  outline: 0;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-bottom: 3px solid #D9D9D9;
  margin-bottom: 20px; /* Add margin at the bottom for spacing */

  img {
    width: 100px;
    height: 70px;
    margin-bottom: 8px;
  }

  ${({ active }) =>
    active &&
    `
      border-bottom: 3px solid #D65B03;
      opacity: 1;
    `}
`;
const ButtonGroup = styled.div`
  display: flex;
`;

const ContentWrapper = styled.div`
  border-radius: 8px;
  ;
  padding: 20px;
  margin: 34px 0 50px; /* Add margin at the top and bottom for spacing */
  /* Custom styles for content tabs */
  .content-tab {
    display: none;
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    height: 544px;
    background-size: contain; /* Ensure the background image fits within the container */
    background-repeat: no-repeat;
    background-position: center center; /* Center the background image */
  }

  /* Background images for content tabs */
  .content-tab.ethical {
    background-image: url('/images/tabImages/Ethicalbg.png');
    color: #ffffff; /* Text color for better visibility */
  }

  .content-tab.industry {
    background-image: url('/images/tabImages/industrybg.png');
    color: #ffffff;
  }

  .content-tab.clients {
    background-image: url('/images/tabImages/clientsbg.png');
    color: #ffffff;
  }

  .content-tab.craftsmanship {
    background-image: url('/images/tabImages/soulfulbg.png');
    color: #ffffff;
  }
`;

const types = [
  { id: 'ethical', title: 'Ethical Decision Maker', image: handShakeImage },
  { id: 'industry', title: 'Industry Mastery', image: industryImage },
  { id: 'clients', title: 'Clients Concierge', image: personImage },
  { id: 'craftsmanship', title: 'Soulful Craftsmanship', image: soulfulCraftsmanshipImage },
];

export default function TabGroup() {
  const [active, setActive] = useState(types[0].id);

  return (
    <>
      <ButtonGroup className='mx-auto items-center justify-center flex mb-[44px]'>
        {types.map(({ id, title, image }) => (
          <Tab key={id} active={active === id} onClick={() => setActive(id)}>
            <img src={image} className='w-[11%]' alt={title} />
            {title}
          </Tab>
        ))}
      </ButtonGroup>
      <ContentWrapper>
        {/* Conditionally render content based on the active tab */}
        {types.map(({ id, title }) => (
          <div
            key={id}
            className={`content-tab ${id}`}
            style={{ display: active === id ? 'block' : 'none' }}
          >
            {/* Custom content for each tab */}
            {active === 'ethical' && (
              <p className='2xl:text-[30px] md:text-[22px] font-light text-white 2xl:w-[49%] md:w-[59%] 2xl:ml-[264px] md:ml-[82px] 2xl:mt-[94px] md:mt-[124px] mb-[124px] text-left font-poppins'>
                At Sun Knits we excel as decision-makers, adept at overcoming obstacles to ensure flawless deliveries. With over two decades of expertise, our seasoned team possesses intricate problem-solving skills, getting our clients heart-fully satisfied remains paramount in every endeavor.
              </p>
              
            )}
            {active === 'industry' && (
              <p className='2xl:text-[30px] md:text-[22px] font-light text-white 2xl:w-[45%] md:w-[59%] 2xl:ml-[264px] md:ml-[52px] 2xl:mt-[94px] md:mt-[124px] mb-[124px] text-left font-poppins'>
                From the very beginning, seasoned industry experts have formed the core of our organisation, driving seamless efficiency across all operations. Their unwavering dedication ensures flawless execution within each department, propelling our continued success.
              </p>
            )}
            {active === 'clients' && (
              <p className='2xl:text-[30px] md:text-[22px] font-light text-white 2xl:w-[39%] md:w-[59%] 2xl:ml-[264px] md:ml-[52px] 2xl:mt-[94px] md:mt-[124px] mb-[124px] text-left font-poppins'>
                Our ultimate obsession is a sense of fulfillment.<br></br> From questions to checkout keeping track of,<br></br> our committed team makes sure your<br></br> experience is flawless. We put your happiness first. Because at Sun Knits we prioritise client benefits, adding significant value to their experience.
              </p>
            )}
            {active === 'craftsmanship' && (
              <p className='2xl:text-[30px] md:text-[22px] font-light text-white 2xl:w-[42%] md:w-[59%] 2xl:ml-[264px] md:ml-[52px] 2xl:mt-[94px] md:mt-[124px] mb-[124px] text-left font-poppins'>
                At Fashions by Sun Knit where every thread is a<br></br> testament to professional passion. Each item is a masterwork, showcasing exquisite craftsmanship. Immerse yourself in a world where skillful artistry meets unparalleled style, meticulously woven into every creation.
              </p>
            )}
          </div>
        ))}
      </ContentWrapper>
    </>
  );
}