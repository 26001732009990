import React from "react";
import { NavBar } from "../Components/navBar";
// import curved from '../../Component Library/curved.png'
import whereImage from '../../Component Library/wheresection.jpg'
import worldlogo from '../../Component Library/worldlogo.svg'
import Rectangle211 from '../../Component Library/Rectangle211.png'
import Rectangle277 from '../../Component Library/Rectangle 277.png'
import sunknitLogo from '../../Component Library/sunKnitLogo.svg'
import fblogo from '../../Component Library/fblogo.svg'
import linkedinlogo from '../../Component Library/linkedinlogo.svg'
import youtubelogo from '../../Component Library/yoyube.svg'
import LabTabs from "./labTabs";
import curved from '../../Component Library/curved.png'
import bannerbg from '../../Component Library/sun_knit_building_copy_1.png'
import phoneabout from '../../Component Library/SunKnitBuilding.jpg'
import { Navbar } from "@material-tailwind/react";
import { FormHelperText } from "@mui/material";
import Footer from "../Components/footer";
import bgbanner from '../../Component Library/aboutusbg1.png'
import curve1 from '../../Component Library/curve1.png'
import curve2 from '../../Component Library/curve2.png'
import phonemap from '../../Component Library/svg/phonemap.png'
import { Link } from "react-router-dom";
import TabGroup from "./tabs";
import { useEffect } from "react";
import handShakeImage from '../../Component Library/svg/handsake.svg';
import bghandsake from '../../Component Library/svg/handsakemobile.png';
import industryImage from '../../Component Library/industryMastery.svg';
import personImage from '../../Component Library/personStar.svg';
import soulfulCraftsmanshipImage from '../../Component Library/soul.svg';
import bgimage1 from '../../Component Library/svg/bgimage1.png';
import bgimage2 from '../../Component Library/svg/bgimage2.png';
import bgimage3 from '../../Component Library/svg/bgimage3.png';
import MobileNavbar from "../Components/navbarMobile";
import { FooterMobile } from "../Components/fotterMobile";

export const AboutUs = () => {
  const backgroundStyle3 = {
    backgroundImage: `url(${bgbanner})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    width: '100%'

  };


  const backgroundStyle = {
    backgroundImage: `url(${whereImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',

  };
  

  const backgroundStyleNew = {
    backgroundImage: `url(${bannerbg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  const bgmobile = {
    backgroundImage: `url(${bghandsake})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };


  
  const bgmobile1 = {
    backgroundImage: `url(${bgimage1})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  
  const bgmobile2 = {
    backgroundImage: `url(${bgimage2})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  
  const bgmobile3 = {
    backgroundImage: `url(${bgimage3})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };
  //   const backgroundStyleCurved = {
  //     backgroundImage: `url(${curved})`,
  //     backgroundSize: 'contain',
  //     backgroundPosition: 'center',
  //     backgroundRepeat: 'no-repeat',  

  // };

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <div className="main">    
      <div className="desktop hidden xl:block">
    <div className="h-screen w-screen">
      <NavBar />
      <div className="flex flex-col" style={backgroundStyle3}>
        {/* <div className="my-[194px]">
          <div className="flex items-center justify-center 2xl:text-[26px] md:text-[22px] font-medium text-black font-poppins 2xl:mt-[0px] md:mt-[-80px]  ">About Us</div>
          <div className="2xl:text-[64px] md:text-[47px] font-semibold flex items-center justify-center font-poppins">Get to Know Us</div>
          <div className="2xl:text-[31px] md:text-[18px] text-right 2xl:w-[93%] md:w-[83%] font-poppins font-light 2xl:ml-[161px] md:ml-[14px] mt-[44px]">
            <p className="2xl:mr-[114px] md:mr-[-14px]">Founded in 2003, Sun Knit Fashions leads in eco-conscious apparel.</p>
            <p className="2xl:mr-[44px] md:mr-[-59px]">Over two decades in Tiruppur, we prioritize green practices and </p>
            <p className="2xl:mr-[44px] md:mr-[-94px]"> ethical values over mere business growth. Committed to crafting trendy </p>
            <p className="2xl:mr-[74px] md:mr-[-44px]">designs, we foster a legacy of morality and growth alongside our cherished </p>
            <p className="2xl:mr-[54px] md:mr-[-84px]">customers. More than garments, we weave a narrative of </p>
            <p className="2xl:mr-[714px] md:mr-[294px]">sustainability and enduring relationships.</p>
          </div>
      
        </div> */}
        <div className="flex flex-row" >
          <div className="flex flex-col w-[50%] h-[244px]"></div>
          <div className="flex flex-col w-[60%] h-[944px] 2xl:mt-[252px]  ">
          <div className="flex items-center justify-center 2xl:justify-start 2xl:text-[26px] md:text-[22px] font-medium text-black font-poppins 2xl:mt-[0px] md:mt-[-80px]  ">About Us</div>
          <div className="2xl:text-[64px] md:text-[47px] 2xl:justify-start font-semibold flex items-center justify-center font-poppins">Get to Know Us</div>
          <div className="2xl:text-[31px] text-left font-poppins leading-[2.8rem] font-light w-[89%]">
          Founded in 2003, Sun Knit Fashions leads in eco-conscious apparel. 
Over two decades in Tiruppur, we prioritise green practices and ethical  
values over mere business growth. Committed to crafting trendy designs, we foster a legacy of morality and growth alongside our cherished customers. More than garments, we weave a narrative of sustainability and enduring relationships.

          </div>
          </div>
          <div>
            
          </div>
        </div>
      </div>
    </div>
    <div className="mt-[74px] w-screen h-screen 2xl:w-full md:w-[84%] 2xl:mx-auto md:mx-auto">
      <TabGroup/>
    </div>



    <div className="2xl:mt-[-92px] md:mt-[294px] lg:mt-[344px] w-[85%] items-center justify-center mx-auto  " >
      <div><img src={curve1} className="w-[14%] lg:pt-[92px]" /></div>
      <div className="text-center items-center justify-center 2xl:text-[45px] md:text-[35px] font-bold mt-[12px] ml-[22px]  font-gilroy  gradient-text1 w-[94%]">
        At Sun Knit Fashions, our commitment is clear: Ethical excellence precedes all. Our garments reflect not just style, but a deep-rooted commitment to morals and values.
      </div>
      <div className="2xl:text-[45px] md:text-[38px] font-bold font-gilroy text-[#303030] items-center text-right 2xl:mr-[174px] md:mr-[124px] ">-Mr. R.K. Samy</div>
      <div className="2xl:text-[27px] md:text-[20px] font-normal font-gilroy text-[#343434] items-center text-right mr-[94px] ">(Founder & Managing Director)</div>
      <div><img src={curve2} className="w-[14%] ml-auto" /></div>
    </div>

    <br></br>

    <br></br><br></br><div className="2xl:h-[1617px] 2xl:w-full md:w-[1360px] md:h-[1450px] bg-red-200" style={backgroundStyle} >
      <div className="ml-[80px] ">
        <div className="font-semibold 2xl:text-[71px] md:text-[60px] font-poppins leading-[2.4rem] pt-[124px] tracking-custom 2xl:mt-[1px] md:-mt[-42px]">Where do we</div>
        <div className="font-semibold 2xl:text-[71px] md:text-[60px] font-poppins tracking-custom">stand?</div>
        <div className="2xl:text-[30px] md:text-[22px] font-normal 2xl:w-[63%] md:w-[59%] font-poppins">As a key player in Tiruppur's apparel sector, we redefine fashion, seamlessly blending sustainability with elegance. Over two decades,<br></br> we've  emerged as a pioneering force in ecologically mindful design, <br></br>setting new benchmarks. Our commitment to cutting-edge technology ensures timeless practices, poised to shape the next decade. Sun Knit Fashions stands as a testament to progressive and enduring contributions to the fashion landscape.</div>
        <div className="my-[124px] ml-[424px]">
          <div className="text-[52px] font-bold font-poppins gradient-tiruppur">TIRUPPUR</div>
          <div className="text-[21px] font-normal font-poppins">Nestled in the heart of Tiruppur, a global hub for<br></br> apparel, we embody style, innovation, and quality.</div>
        </div>
        {/* <div className="2xl:mt-[-124px]"><img src={worldlogo}/></div> */}
        <div className="2xl:ml-[1194px] md:ml-[824px] 2xl:my-[294px] md:my-[324px]  2xl:W-[27%] md:w-[45%] my-[414px] ">
          <img className="w-[7%] 2xl:my-[124px]" src={worldlogo}/>
          <div className="2xl:text-[21px] md:text-[17px] font-normal	ml-[12px] text-[#303030] xl:w-full 2xl:mt-[-122px] font-poppins lg:ml-[-48px] ">We export to Countries, crossing all boundaries.<br></br>Our products, which are expertly made with a <br></br>global flair, infuse every region of the world with<br></br> style.</div>
        </div>
      </div>
    </div>

    
      <div className="mt-[74px] mb-[74px] ">
        <div className="2xl:text-[75px] md:text-[60px] font-semibold flex items-center justify-center font-poppins">What makes us different?</div>
        <div className="2xl:text-[27px] md:text-[22px] text-center font-normal mx-auto 2xl:w-[74%] md:w-[8  4%] my-[28px] font-poppins">
          Sun Knit Fashions is distinctive in terms of its
          <span className="text-[#D65B03] font-semibold font-poppins max-w-full"> 25-year tradition and GOTS and GRS certifications.</span>
          What separates us is our adherence to sustainable garment manufacturing, fair trade, and ethical methods. Several fabric capabilities, advanced technology, and an ongoing dedication to transparency and quality highlight our exclusive branding in the fashion industry.
        </div>
      </div>
   
    <Footer />
  </div>

    
  <div className="mobile xl:hidden sm:block mx-auto">
           <div>
            <MobileNavbar/>
           </div>
          <div className="flex items-center text-center justify-center text-[16px] font-medium text-black font-poppins">About us</div>
          <div className="text-[19px] text-center font-semibold flex items-center justify-center font-poppins">Get to Know Us</div>
          <div className="text-[14px] font-poppins font-normal text-left  mx-auto leading-[1.8rem] ml-[34px] mr-[27px] mt-[8px]">
            Founded in 2003, Sun Knit Fashions leads in eco-conscious apparel. Over two decades in Tiruppur, we prioritize green practices and ethical values over mere business growth.  Committed to crafting trendy designs, we foster a legacy of morality and growth alongside our cherished customers. More than garments, we weave a narrative of sustainability and enduring relationships.
          </div>
          <div>
          <div><img src={phoneabout} className=" w-[98%]"/></div>
          </div>
           

           <div className="">
          <div className="tab-section h-[1784px]">
            <div className="tab1 ml-[8px]">
          <div><img src={handShakeImage} className="w-[15%] flex mx-auto pt-[24px]" /></div>
          <div className="font-poppins font-semibold text-13px] text-black text-center mt-[12px]">Ethical Decision Maker</div>
          <div className="w-[379px] h-[525px] rounded-[23px] mt-[12px] " style={bgmobile}>
           <div className="text-white text-[13px] font-poppins leading-[1.5rem] text-center pt-[19px] w-[95%] mx-auto pt-[50px] leading-[1.7rem]"> At Sun Knits we excel as decision-makers, adept at overcoming obstacles to ensure flawless deliveries. With over two decades of expertise, our seasoned team possesses intricate problem-solving skills, getting our clients heart-fully satisfied remains paramount in every endeavor.
</div>
          </div>
          </div>
          <div className="tab2">
          <div><img src={industryImage} className="w-[15%] flex mx-auto pt-[24px]" /></div>
          <div className="font-poppins font-semibold text-13px] text-black text-center mt-[12px]">Industry Mastery</div>
          <div className="w-[379px] h-[525px] rounded-[23px] mt-[12px] mx-auto" style={bgmobile1}>
           <div className="text-white text-[13px] font-poppins leading-[1.5rem] text-center pt-[19px] w-[95%] mx-auto pt-[50px] leading-[1.7rem]">From the very beginning, seasoned industry experts have formed the core of our organisation, driving seamless efficiency across all operations. Their unwavering dedication ensures flawless execution within each department, propelling our continued success.</div>
          </div>
          </div>
          <div className="tab3">
          <div><img src={personImage} className="w-[15%] flex mx-auto pt-[24px]" /></div>
          <div className="font-poppins font-semibold text-13px] text-black text-center mt-[12px]">Clients Conceriege</div>
          <div className="w-[379px] h-[525px] rounded-[23px] mt-[12px] mx-auto" style={bgmobile2}>
           <div className="text-white text-[13px] font-poppins leading-[1.5rem] text-center pt-[19px] w-[95%] mx-auto mx-auto pt-[50px] leading-[1.7rem]">                Our ultimate obsession is a sense of fulfillment.<br></br> From questions to checkout keeping track of,<br></br> our committed team makes sure your<br></br> experience is flawless. We put your happiness first. Because at Sun Knits we prioritise client benefits, adding significant value to their experience.</div>
          </div>
          </div>
          <div className="tab4">
          <div><img src={soulfulCraftsmanshipImage} className="w-[15%] flex mx-auto pt-[24px]" /></div>
          <div className="font-poppins font-semibold text-13px] text-black text-center mt-[12px]">Soulful Craftmanship</div>
          <div className="w-[379px] h-[525px] rounded-[23px] mt-[12px] mx-auto" style={bgmobile3}>
           <div className="text-white text-[13px] font-poppins leading-[1.5rem] text-center pt-[19px] w-[95%] mx-auto pt-[50px] leading-[1.7rem]">At Fashions by Sun Knit, where every thread is a <br></br>  testament to professional passion. Each item is a <br></br>  masterwork, showcasing exquisite craftsmanship.<br></br>Immerse yourself in a world where skillful artistry<br></br>  meets unparalleled style, meticulously woven into<br></br>    every creation.</div>
          </div>
          </div>
          </div>
          </div>
          
           <div className="mt-[-74px]">
          <div className="mt-[924px] w-[85%] items-center justify-center mx-auto" >
      <div><img src={curve1} className="w-[24%]" /></div>
      <div className="text-left items-center justify-center text-[20px] font-bold ml-[44px]  font-gilroy  gradient-text1 w-[84%]">
        At Sun Knit Fashions, our commitment is clear: Ethical excellence precedes all. Our garments reflect not just style, but a deep-rooted commitment to morals and values.
      </div>
      <div className="text-[20px] font-extrabold font-gilroy text-[#303030] items-center text-right mr-[64px] mt-[12px] ">-Mr. R.K. Samy</div>
      <div className="text-[10px] font-normal font-gilroy text-[#343434] items-center text-right mr-[52px] ">(Founder & Managing Director)</div>
      <div><img src={curve2} className="w-[24%] ml-auto mt-[12px]" /></div>
    </div>
    </div>
    <div className="bg-[#f9f9f9] mt-[32px]">
    <div className="font-bold text-[21px] font-poppins leading-[2.4rem] pt-[14px] ml-[16px]">Where do we stand?</div>
        <div className="text-[15px] font-normal font-poppins mx-auto leading-[1.8rem] ml-[22px]">As a key player in Tiruppur's apparel sector, we redefine fashion, seamlessly blending sustainability with elegance. Over two decades, we've emerged as a pioneering force in ecologically mindful design, setting new benchmarks. Our commitment to cutting-edge technology ensures timeless practices, poised to shape the next decade. Sun Knit Fashions stands as a testament to progressive and enduring contributions to the fashion landscape.</div>
        <div>
          <img src={phonemap} className="w-[94%] ml-auto mt-[12px]" />
          <div className="mt-[-254px]">
          <div className="text-[18px] font-bold font-poppins gradient-thirupur text-right mr-[119px] mt-[22px]">TIRUPPUR</div>
          <div className="text-[7px] font-normal font-poppins text-right w-[84%] ml-[18px] mt-[-2px]">Nestled in the heart of Tiruppur, a global hub for</div>
          <div className="text-[7px] font-normal font-poppins text-right w-[84%] ml-[28px]">apparel, we embody style, innovation, and quality.</div>
          </div>
          </div>
          <div className="mt-[194px] ml-[38px] pb-[32px]">
          <div><img src={worldlogo} className="w-[9%]" /></div>
          <div className="text-[14px] font-medium	 text-[#303030]  mt-[12px]  font-poppins">We export to Countries, crossing all boundaries.Our products, which are expertly made with a global flair, infuse every region of the world with style.</div>
        </div>
    </div>
    
        
        <div className="text-[20px] font-semibold flex items-center justify-center font-poppins text-center mt-[22px]">What makes us different?</div>
        <div className="text-[12px] text-center font-normal mx-auto w-[81%] my-[18px] font-poppins text-center leading-[1.7rem]">
          Sun Knit Fashions is distinctive in terms of its<br></br>
          <span className="text-[#D65B03] font-semibold font-poppins w-[100%]"> 25-year tradition and GOTS and GRS certifications.</span>
          What separates us is our adherence to sustainable garment manufacturing, fair trade, and ethical methods. Several fabric capabilities, advanced technology, and an ongoing dedication to transparency and quality highlight our exclusive branding in the fashion industry.
        </div>

        <div className='xl:hidden sm:block'>
          <FooterMobile/>
       
      </div>
    </div>



  </div>
  

    
  )
}