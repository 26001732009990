import React, { useState, useEffect } from 'react';
import image1 from '../../Component Library/ouinfra1.png';
import image2 from '../../Component Library/ouinfra2.png';
import image3 from '../../Component Library/ouinfra3.png';
import image4 from '../../Component Library/ouinfra4.png';
import { Link } from 'react-router-dom';

const images = [image1, image2,image3,image4];


const ImageSlideshow = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [hovered, setHovered] = useState(false);
  
  useEffect(() => {
    const interval = setInterval(() => {
      // Update the current image index to show the next image
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change the interval as needed (in milliseconds)

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);





  return (
    <div>
    <div className='desktop hidden xl:block'>
 
    <div className="relative w-full 2xl:h-[944px] md:h-[944px] sm:h-[544px] overflow-hidden rounded-[54px] cursor-pointer">
      <div
        className={`absolute bottom-8 left-8 z-10 transition-transform ${
          hovered ? '-translate-y-12' : 'translate-y-0'
        }`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <div className={`2xl:text-[55px] md:text-[38px] font-semibold font-poppins ${
          hovered ? 'text-orange-500' : 'text-white'
        }`}><Link to="/infrastructure">Our Infrastructure</Link> {hovered && <span className='ml-2 text-orange-500'>&gt;</span>}</div>
        <div
          className={`2xl:text-[24px] md:text-[17px] font-poppins font-normal 2xl:w-[65%] md:w-[65%] transition-opacity ${
            hovered ? 'opacity-100' : 'opacity-0'
          } text-white`}
        >
          <Link to="/infrastructure">Explore our state-of-the-art infrastructure through captivating visuals.</Link>
        </div>
      </div>
      <div className="absolute top-0 left-0 w-full h-full">
        {images.map((imageUrl, index) => (
          <div
            key={index}
            className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
              index === currentImageIndex ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <img
              src={imageUrl}
              alt={`Image ${index + 1}`}
              className="w-full h-full object-cover rounded-[14px]"
            />
          </div>
        ))}
      </div>
    </div>
         
    </div>


    </div>
  );
};

export default ImageSlideshow;