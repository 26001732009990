import React from "react";
import { NavBar1 } from "../Components/navBar1";
import banner from '../../Component Library/svg/viewbanner.png'
import expand1 from '../../Component Library/svg/expand1.png'
import expand2 from '../../Component Library/svg/expand2.png'
import banner1 from '../../Component Library/prdouctnew-mbbanner.png'
import expandnew1 from '../../Component Library/prdouctnew-first1.png'
import expandnew2 from '../../Component Library/prdouctnew-2.png'
import MobileNavbar from "../Components/navbarMobile";
import { FooterMobile } from "../Components/fotterMobile";

export const PrdouctsNew = () => {
  return (
    <div>
    <div className="hidden 2xl:block md:block sm:hidden">
      <div className="2xl:w-[1920px] 2xl:-[1611px]  bg-black">
   <NavBar1/>
   <div className="text-[41px] font-medium font-poppins flex items-center justify-center text-[#6A6A6A] mt-[114px]">Half Sleeve </div>
   <div className="w-[89%] mx-auto ">
   <img src={banner} />
   </div>
   <div className="2xl:w-[75%] md:w-[75%]  mx-auto gap-[70px] flex mt-[34px]">
   <img src={expand1} className="2xl:w-[75%] md:w-[45%]  mx-auto " />
   <img src={expand2} className="2xl:w-[75%] md:w-[45%]  mx-auto " />

   </div>
  </div>
    </div>

    <div className="hidden 2xl:hidden md:hidden sm:block">
      <div className="2xl:w-[1920px] 2xl:-[1611px] h-[1244px]  bg-black">
   <MobileNavbar/>
   {/* <div className="text-[41px] font-medium font-poppins flex items-center justify-center text-[#6A6A6A] mt-[114px]">Half Sleeve </div> */}
   <div className="w-[89%] mx-auto mt-[42px]">
   <img src={banner1} />
   </div>
   <div className="2xl:w-[75%] md:w-[75%]  mx-auto gap-[70px] flex mt-[34px]">
   <img src={expandnew1} className="2xl:w-[75%] md:w-[45%]  mx-auto " />
   </div>
   <img src={expandnew2} className="2xl:w-[75%] md:w-[45%] mt-[22px]  mx-auto " />
  </div>
  <FooterMobile/>
    </div>

</div>

  
  );
};