import React from 'react';
import { NavBar } from '../Components/navBar';
// import landingImage from '../../Component Library/svg/homebanner.svg';
import playicon from '../../Component Library/svg/playicon.svg';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import MobileNavbar from '../Components/navbarMobile';
import Bgvideo from './Bgvideo';
import Video from './Bgvideo';
import NewVideo from './Bgvideo';
import HeroSection from './Bgvideo';
import Hero from './heroSection';
// import landingImage from '../../Component Library/baner1.png';
import HeroSections from './heroSection';
import { useState } from 'react';
import MbSections from './mobileHerSections';
// import video from './videos/sample.mp4';
const LandingPage12 = () => {

  const backgroundVideoStyle: React.CSSProperties = {
    position: 'relative',
    width: '100%',
    height: '100vh',
    objectFit:'cover',
    // backgroundSize:'contain',
    overflow: 'hidden',
  };

  // const iframeStyle: React.CSSProperties = {
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   transform: 'translate(-50%, -50%)',
  //   width: '100%',
  //   height: 'auto',
  //   minWidth: '100%',
  //   minHeight: '100%',
  // };

  const contentStyle: React.CSSProperties = {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    zIndex: 1,
    color: 'white', // adjust text color as needed
    // marginTop98px
  };

  // const bgimage = {
  //   backgroundImage: `url(${landingImage})`,
  //   backgroundSize: 'cover',
  //   backgroundPosition: 'center',
  //   backgroundRepeat: 'no-repeat',
  //   height: '100%',
  //   width: '100%'

  // };

  useEffect(() => {
    // Set the margin and padding of body and html to zero
    document.body.style.margin = '0';
    document.body.style.padding = '0';
    document.documentElement.style.margin = '0';
    document.documentElement.style.padding = '0';
  }, []);


  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };


  return (
    <div className=''>
 <div className='flex flex-col gap-[25px] h-screen w-screen desktop hidden xl:block '>
        <div className='hidden xl:block'>
          <NavBar />
        </div>
        <div>
          <HeroSections/>
        </div>
      <div className='txt' >

        <div className='flex flex-col gap-[10px] rounded-[50px] items-end justify-end 2xl:mx-[-40px] 2xl:my-[-20px] lg:py-[140px]' style={contentStyle}>
          <div className='text-[74px] font-normal text-white flex items-end justify-end font-gilroy mr-[93px] tracking-customs1 2xl:mt-[0px] '>Discover Impeccable Quality</div>
          <div className='text-[74px] font-normal text-white flex items-end justify-end font-gilroy mr-[93px] tracking-customs mt-[-34px]'>and Ethical Fashion.</div>
          <div className='text-[28px] font-normal text-white flex items-end justify-end font-gilroyReqular mr-[93px] tracking-customs'>At Sun Knits sustainability meets style. Explore our premium garmenting practices.</div>
          <div className="flex items-center mr-[84px]">
            {/* Your button */}
            <Link to="/contact">
              <button className="blur-button">Enquire</button>
            </Link>
            {/* Divider */}
            <div className="border-r border-gray-400 h-6 mr-2"></div>

            {/* Play video icon and text */}
            <Link to="https://www.youtube.com/watch?v=TEAA8lVmESY" target="_blank">
            <div className="flex items-center ml-[8px]">
  <button className="glow-on-hover flex items-center justify-center" type="button">
    {/* You can use your playicon image here */}
    <img src={playicon} height='30px' width='35px' className="mr-2" alt="Play Icon"/>
    <span className='watchvideo'>Watch Video</span>
  </button>
</div>
            </Link>
          </div>
        </div>
      </div>
      </div>

       <div className='mobile xl:hidden sm:block'>
       <div className='flex flex-col gap-[25px] h-screen w-screen desktop'>
       <div className='hidden sm:block'>
          <MobileNavbar/>
        </div>
        <div className='mt-[-29px]'>
          <MbSections/>
        </div>
      <div className='txt' >

        <div className='flex flex-col gap-[10px] rounded-[50px] items-end justify-end px-[20px] py-[20px] ' style={contentStyle}>
          <div className='text-[32px] font-gilroy font-semibold  text-white tracking-customs leading-[2.2rem] sm:mt-[-732px]'>Discover Impeccable <br></br><span className='ml-[122px]'>Quality and</span> <br></br> <span className='ml-[79px]'>Ethical Fashion.</span></div>
          <div></div>
          <div className='text-[12px] font-normal text-white text-right mr-[-82px] font-gilroyReqular mr-[7px] w-full mt-[-12px]'>At Sun Knits sustainability meets style.<br></br> Explore our premium garmenting practices.</div>
          <Link to="https://www.youtube.com/watch?v=TEAA8lVmESY" target="_blank">
            <div className="flex items-center ml-[8px]">
  <button className="glow-on-hover flex items-center justify-center" type="button">
    {/* You can use your playicon image here */}
    <img src={playicon}  className="mr-2 xl:w-[32px] xl:h-[32px] sm:w-[20px] sm:h-[20px] " alt="Play Icon"/>
    <span className='watchvideo xl:text-[20px] sm:text-[12px]'>Watch Video</span>
  </button>
</div>
            </Link>
        </div>
      </div>
            
    
      </div>
       </div>

    </div>
     
    
  );
};

export default LandingPage12;
