

import React from "react";
import styled, { keyframes } from 'styled-components';
import gender from '../../Component Library/Gender Equity.png';
import chemical from '../../Component Library/Chemical.png';
import client from '../../Component Library/Client.png';
import water from '../../Component Library/Water.png';
import solar from '../../Component Library/solar.png';

import healthy from '../..//Component Library/Healthy.png';
import { Texttile } from "./texttile";
import certificate1 from '../../Component Library/GOTS Global Organic Textile Standard 2.png'
import certificate2 from '../../Component Library/Globalicn.png'
import certificate4 from '../../Component Library/certificate4.png'
import certificate5 from '../../Component Library/Globalicn - Copy.png'
import fblogo from '../../Component Library/fblogo.svg'
import linkedinlogo from '../../Component Library/linkedinlogo.svg'
import youtubelogo from '../../Component Library/youtubelogo.svg'
import sunknitLogo from '../../Component Library/sunKnitLogo.svg'
import firstImage from '../../Component Library/Gots Front.png'
import secondImage from '../../Component Library/Grs Front.png'
import { Link } from "react-router-dom";
import Footer from "../Components/footer";
import textile1 from '../../Component Library/Gots Back.png'
import textile2 from '../../Component Library/Grs Back.png'
import { FooterMobile } from "../Components/fotterMobile";
import bg1 from '../../Component Library/carbon1.png';
import bg2 from '../../Component Library/carbon2.png';

import MyComponent from "./progbar";


// const runningLineAnimation = keyframes`
//   0% {
//     transform: translateX(0);
//   }
//   100% {
//     transform: translateX(100%);
//   }
// `;

// // Styled component for the running line
// const RunningLine = styled.div`
//   position: relative;
//   overflow: hidden;
//   height: 2px;
//   width: 100%;

//   &::before {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     height: 100%;
//     width: 100%;
//     background-color: black;
//     animation: ${runningLineAnimation} 2s linear infinite;
//   }
// `;

export const SustainabilityComponentFour = () => {

  const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
  `;

  const carbonbg1 = {
    backgroundImage: `url(${bg1})`,

    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };


  const carbonbg2 = {
    backgroundImage: `url(${bg2})`,

    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };




  return (
    <div>
      <div>
      <div className="bg-[#f4f4f4] h-[730px] pb-[44px]  hidden xl:block">
      <div className="mt-[24px] desktop   mt-[-162px]">
      <div className="text-[64px] font-poppins font-bold items-center justify-center flex prime pt-[30px]">Our Prime Concern  </div>
      <div className="icons-section flex 2xl:w-full md:w-[90%] h-full mb-[44px]">
        <Column>
          <div className="flex ml-[141px] mt-[54px] flex-row">
            <img src={gender} alt="Gender Equity" />
            <div className="flex felx-row w-full">
              <div className="skill-box ml-[34px]">
                <span className="title">Gender Equity</span>
                <div className="skill-bar">
                  <span className="skill-per gender">
                    <span className="tooltip">95%</span>
                  </span>
                </div>
              </div>

            </div>

          </div>

          <div className="flex ml-[141px] mt-[54px] flex-row">
            <img src={water} className="w-[133px] h-[121px]" alt="Gender Equity" />
            <div className="flex felx-row w-full">
              <div className="skill-box ml-[34px]">
                <span className="title">Water Utilization</span>
                <div className="skill-bar">
                  <span className="skill-per water">
                    <span className="tooltip">50%</span>
                  </span>
                </div>
              </div>

            </div>

          </div>



          <div className="flex ml-[141px] mt-[54px] flex-row">
            <img src={solar} alt="Gender Equity" className="w-[133px] h-[121px]" />
            <div className="flex felx-row w-full">
              <div className="skill-box ml-[34px]">
                <span className="title">Solar Energy</span>
                <div className="skill-bar">
                  <span className="skill-per solar">
                    <span className="tooltip">90%</span>
                  </span>
                </div>
              </div>

            </div>

          </div>


        </Column>

        <Column>


          <div className="flex ml-[141px] mt-[54px] flex-row">
            <img src={chemical} alt="Gender Equity" className="w-[133px] h-[121px]" />
            <div className="flex felx-row w-full">
              <div className="skill-box ml-[34px]">
                <span className="title">Chemical Consumption  </span>
                <div className="skill-bar">
                  <span className="skill-per chemical">
                    <span className="tooltip">30%</span>
                  </span>
                </div>
              </div>

            </div>

          </div>


          <div className="flex ml-[141px] mt-[54px] flex-row">
            <img src={healthy} alt="Gender Equity" className="w-[133px] h-[121px]" />
            <div className="flex felx-row w-full">
              <div className="skill-box ml-[34px]">
                <span className="title">Healthy Collaboration</span>
                <div className="skill-bar">
                  <span className="skill-per healthy">
                    <span className="tooltip">81%</span>
                  </span>
                </div>
              </div>

            </div>

          </div>


          <div className="flex ml-[141px] mt-[54px] flex-row">
            <img src={client} alt="Gender Equity" className="w-[133px] h-[121px]" />
            <div className="flex felx-row w-full">
              <div className="skill-box ml-[34px]">
                <span className="title">Client Contentment</span>
                <div className="skill-bar">
                  <span className="skill-per gender">
                    <span className="tooltip">95%</span>
                  </span>
                </div>
              </div>

            </div>

          </div>
        </Column>
      </div>

      </div>

      
      </div>


      <div className="carbon w-full 2xl:h-[885px] md:h-[714px] bg-[#52738D] hidden xl:block">
        <div className=" w-[89%] mx-auto flex gap-[58px]">
        <div className="w-[50%] 2xl:h-[694px] md:h-[554px] flex flex-col 2xl:mt-[96px] md:mt-[77px] rounded-[50px]" style={carbonbg1}>
          <div className=" ml-[59px] w-[98%]">
          <div className="font-medium 2xl:text-[54px] md:text-[34px] font-poppins gradient-texts mt-[80px] tracking-[-0.04em] 2xl:leading-[4.2rem] md:leading-[2.7rem]">Assessing Environmental</div>
          <div className="font-medium 2xl:text-[54px] md:text-[34px] font-poppins text-white 2xl:leading-[4.2rem] md:leading-[2.7rem] tracking-[-0.04em] "><span className="2xl:leading-[4.2rem] md:leading-[2.7rem] font-medium 2xl:text-[54px] md:text-[34px] font-poppins gradient-texts">Impact</span> at Sun knits </div>
          {/* <div className="text-white font-medium 2xl:text-[54px] md:text-[34px] font-poppins 2xl:leading-[4.2rem] md:leading-[2.7rem] tracking-[-0.04em]">Industry.</div> */}
          </div>
          <div className="ml-[60px] mt-[45px] font-poppins font-normal 2xl:w-[89%] md:w-[84%] text-white 2xl:text-[24px] md:text-[17px] 2xl:leading-[2.8rem] md:leading-[1.8rem]">
          At Sun Knits, we prioritise environmental stewardship by implementing regular carbon monitoring in our textile processes. This ensures diligent observation of emissions, allowing us to take proactive measures to mitigate our environmental impact, aligning with our commitment to sustainability and responsible manufacturing practices.</div>          
        </div>
        <div className="w-[50%] 2xl:h-[694px] md:h-[554px] flex flex-col 2xl:mt-[96px] md:mt-[77px] rounded-[50px] " style={carbonbg2}>
          <div className="font-medium 2xl:text-[40px] md:text-[26px]  text-white font-poppins 2xl:leading-[2.4rem] md:leading-[1.7rem] border-l-3 gradient-border ml-[50px]  2xl:mt-[544px] md:mt-[444px] pl-[12px] tracking-[-0.02em] ">Carbon <br></br>Monitoring</div>
        </div>
        </div>
       
      </div>






  <div className="hidden xl:block 2xl:w-full md:w-[90%] mx-auto">
  <div className="flex items-center justify-center text-[50px] font-poopins font-bold tracking-customs2 mt-[59px] ">Textile Industry Certifications</div>
  <div className="flex items-center justify-center gap-[81px] h-[680px] mb-[40px] mt-[-92px]">
<div className="textile-container1">
           <img src={firstImage}  />
           
         </div>
         <div className="textile-container2">
           <img src={secondImage}  />
           
         </div>
</div>
  </div>

 
 <div className="hidden hidden xl:block">
   <Footer/>
 </div>


      </div>
     
   

      
     <div className="mobile xl:hidden sm:block bg-[#F3F3F3] mt-[22px] pb-[42px] ">
       <div className="font-bold font-poppins text-[22px] text-center pt-[32px] pb-[22px]">Our Prime Concern </div>
             
     

     <div className="skill-box ml-[34px] flex flex-row items-center mb-[14px]">
  <img src={gender} alt="Gender Equity" className="w-[65px] h-[65px] mr-2" />
  <div>
    <span className="title">Gender Equity</span>
    <div className="skill-bar flex flex-row items-center bg-green-200">
      <div className="skill-per gender bg-red-200">
        <div className="tooltip">91%</div>
      </div>
    </div>
  </div>
</div>


<div className="skill-box ml-[34px] flex flex-row items-center mt-[44px] mb-[44px]">
  <img src={water} alt="Gender Equity" className="w-[65px] h-[65px] mr-2" />
  <div>
    <span className="title">Water Utilization</span>
    <div className="skill-bar flex flex-row items-center">
      <div className="skill-per water">
        <div className="tooltip ">50%</div>
      </div>
    </div>
  </div>
</div>

<div className="skill-box ml-[34px] flex flex-row items-center mb-[44px]">
  <img src={solar} alt="Gender Equity" className="w-[65px] h-[65px] mr-2" />
  <div>
    <span className="title">Solar Energy</span>
    <div className="skill-bar flex flex-row items-center">
      <div className="skill-per solar">
        <div className="tooltip">90%</div>
      </div>
    </div>
  </div>
</div>


<div className="skill-box ml-[34px] flex flex-row items-center mb-[44px]">
  <img src={chemical} alt="Gender Equity" className="w-[65px] h-[65px] mr-2" />
  <div>
    <span className="title">Chemical Consumption</span>
    <div className="skill-bar flex flex-row items-center">
      <div className="skill-per chemical">
        <div className="tooltip">30%</div>
      </div>
    </div>
  </div>
</div>

<div className="skill-box ml-[34px] flex flex-row items-center mb-[44px]">
  <img src={healthy} alt="Gender Equity" className="w-[65px] h-[65px] mr-2" />
  <div>
    <span className="title">Healthy Colaboration</span>
    <div className="skill-bar flex flex-row items-center">
      <div className="skill-per healthy">
        <div className="tooltip">81%</div>
      </div>
    </div>
  </div>
</div>
   

     <div className="skill-box ml-[34px] flex flex-row items-center mt-[44px]">
  <img src={client} alt="Gender Equity" className="w-[65px] h-[65px] mr-2" />
  <div>
    <span className="title">Client Contentment</span>
    <div className="skill-bar flex flex-row items-center">
      <div className="skill-per gender">
        <div className="tooltip">94%</div>
      </div>
    </div>
  </div>
</div> 
         

      
     </div>



     <div className="carbon w-full h-[715px] bg-[#52738D] xl:hidden sm:block ">

         <div className="w-[94%] mx-auto pt-[44px]">
         <div className="w-full h-[321px] flex flex-col 2xl:mt-[96px] rounded-[17px] " style={carbonbg2}>
          <div className="font-medium text-[18px] text-white font-poppins leading-[1.2rem] border-l-2 gradient-border ml-[25px] mt-[254px] pl-[12px] tracking-[-0.02em] ">Carbon <br></br>Monitoring</div>
        </div>

        <div className="h-[299px] rounded-[17px] mt-[14px]"style={carbonbg1}  >
            <div className="w-full pt-[31px] pl-[27px]">
            <div className="font-medium w-full text-[20px] font-poppins gradient-texts tracking-[-0.04em] leading-[1.7rem]">Assessing Environmental</div>
            <div className="font-medium w-full text-[20px] font-poppins text-white leading-[1.7rem] tracking-[-0.04em] "><span className="leading-[1.2rem] font-medium 2xl:text-[54px] sm:text-[20px] font-poppins gradient-texts">Impact</span> at Sun Knits. </div>
            <div className="text-[12px] text-white w-[91%] leading-[1.4rem] mt-[24px]">At Sun Knits, we prioritise environmental stewardship by implementing regular carbon monitoring in our textile processes. This ensures diligent observation of emissions, allowing us to take proactive measures to mitigate our environmental impact, aligning with our commitment to sustainability and responsible manufacturing practices.</div>
            </div>
          
          
        </div>
         </div>

        {/* <div className=" w-[89%] mx-auto">
        <div className="w-full h-[299px] flex flex-row 2xl:mt-[96px] rounded-[17px]" style={carbonbg1}>
          <div className=" ml-[59px] w-[98%]">
          <div className="font-medium 2xl:text-[54px] sm:text-[20px] font-poppins gradient-texts mt-[80px] tracking-[-0.04em] leading-[4.2rem]">Assessing Environmental</div>
          <div className="font-medium 2xl:text-[54px] sm:text-[20px] font-poppins text-white leading-[4.2rem] tracking-[-0.04em] "><span className="leading-[2.8rem] font-medium 2xl:text-[54px] sm:text-[20px] font-poppins gradient-texts">Impact</span> in the Textile </div>
          <div className="text-white font-medium 2xl:text-[54px] sm:text-[20px] font-poppins leading-[4.2rem] tracking-[-0.04em]">Industry.</div>
          </div>
          <div className="ml-[60px] mt-[45px] font-poppins font-normal w-[89%] text-white 2xl:text-[24px] sm:text-[12px] leading-[2.8rem]">
          Carbon monitoring is utilized in the textile industry to track emissions and assess environmental impact, aiding companies in reducing their carbon footprint. This practice aligns with broader sustainability initiatives, guiding efforts to optimize processes, invest in renewables, and engage in carbon offsetting programs.
          </div>
          
        </div>
        <div className="w-[50%] h-[694px] flex flex-col 2xl:mt-[96px] rounded-[50px] " style={carbonbg2}>
          <div className="font-medium text-[40px] text-white font-poppins leading-[2.4rem] border-l-3 gradient-border ml-[50px] mt-[544px] pl-[12px] tracking-[-0.02em] ">Carbon <br></br>Monitoring</div>
        </div>
        </div> */}
       
      </div>




     <div className="texttile xl:hidden sm:block">
<div className="text-[24px] font-poppins text-black font-bold items-center justify-center flex pt-[32px] leading-[1.6rem]">Textile Industry </div>
<div className="text-center text-[24px] font-poppins text-black font-bold items-center justify-center flex pt-[32px] mt-[-22px] leading-[1.2rem] ">Certifications</div>

<div className="flex w-full justify-center mt-[22px]">
  <div className="flex flex-col mx-auto"> {/* Change: Added flex-col */}
    <div className="flex flex-col relative w-[321px] h-[167px] mr-8 rounded-[25px] overflow-hidden ml-[44px]">
      <img src={textile1} />
    </div>
    <div className="flex flex-col relative w-[321px] h-[167px] mr-8 rounded-[25px] overflow-hidden ml-[44px] mt-[17px]">
      <img src={textile2} />
    </div>
   
  </div>
</div>
</div>


<div className='xl:hidden sm:block'>
  <FooterMobile/>
        {/* <div className='footer w-[99%] h-[226px] bg-black mx-auto mt-[34px] flex '>
          <div className='flex flex-col w-[50%] bg-black h-[144px] ml-[42px] mt-[32px]'>
            <div className='font-normal text-white font-poppins text-[16px] pb-[14px]'>
              <Link to="/">
                Home
              </Link>
            </div>
            <div className='font-normal text-white font-poppins text-[16px] pb-[14px]'>
              <Link to="/about">About us</Link>

            </div>
            <div className='font-normal text-white font-poppins text-[19px] pb-[14px]'>
              <Link to="/infrastructure">Infrastructure</Link>

            </div>
            <div className='font-normal text-white font-poppins text-[19px]'>
              <Link to="/products">
                Products
              </Link>
            </div>
          </div>
          <div className='flex flex-col w-[50%] bg-black h-[144px] mt-[32px]'>
            <div className='font-normal text-white font-poppins text-[19px] mb-[14px]'>
              <Link to="/sustainability">
                Sustainbility
              </Link>
            </div>

            <div className='font-normal text-white font-poppins text-[19px]'>
              <Link to="/contact">
                Conatct Us
              </Link>
            </div>
          </div>
        </div>
        <div className='bg-black w-[99%] mx-auto h-[544px]'>
          <div className='w-[50%] flex mx-auto'> <img src={sunknitLogo} className='h-[174px]' /></div>
          <div className='text-center text-white text-[15px] leading-[2.4rem]'>S.F.NO: 250/1,</div>

          <div className='text-center text-white text-[15px] leading-[2.4rem]'>24,25 Kanchi Kamatchi Nagar,</div>

          <div className='text-center text-white text-[15px] leading-[2.4rem]'>Kangayampalayam Pudur,Kangayam Road,</div>

          <div className='text-center text-white text-[15px]'>Tirupur - 641 064</div>

          <div className='h-[1px] w-[70%] bg-[#ffff] mt-[28px] mx-auto'></div>

          <div className='text-[17px] text-white font-medium text-center mt-[24px]'>Follow Us</div>
          <div className='flex flex-row gap-[25px] items-center justify-center mt-[24px]'>
            <img src={fblogo}></img>
            <img src={linkedinlogo}></img>
            <img src={youtubelogo}></img>
          </div>
          <div className='text-[14px] font-medium text-white text-center mt-[24px]'>2024  Sunknit Garments . All Rights Reserved</div>

        </div> */}
      </div>


    </div>
  );
};
