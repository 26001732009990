import React from 'react';
import { NavLink } from 'react-router-dom';
import sunknitLogo from '../../Component Library/sunKnitLogo.svg';
import { Link } from 'react-router-dom';
export const NavBar: React.FC = () => {
  return (
    <div className="w-screen h-[80px] flex-row flex">
      <div className="flex w-[15%] h-full items-center justify-start px-[25px] mx-[54px]">
      <Link to="/"><img src={sunknitLogo} width='285px' height='58px'/></Link>
      </div>
      <div className="flex flex-row w-[85%] h-full items-center justify-end px-[45px] gap-[35px] mx-[74px]">
        <NavItem to="/" text="Home" />
        <NavItem to="/about" text="About us" />
        <NavItem to="/infrastructure" text="Infrastructure" />
        <NavItem to="/products" text="Products" />
        <NavItem to="/sustainability" text="Sustainability" />
        <NavItem to="/contact" text="Contact us" />
        
      </div>
    </div>
  );
};

interface NavItemProps {
  to: string;
  text: string;
}

const NavItem: React.FC<NavItemProps> = ({ to, text }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <NavLink
      to={to}
      className={`font-normal  2xl:text-[23px] md:text-[17px] font-poppins  relative overflow-hidden transition-font ${
        isHovered ? 'font-semibold' : ''
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {text}
      <div
        className={`absolute bottom-0 left-1/4 w-1/2 h-0.5 bg-orange-500 transition-transform origin-left transform translate-x-[-50%] ${
          isHovered ? 'scale-x-150' : 'scale-x-0'
        }`}
      ></div>
    </NavLink>
  );
};