import React from 'react';
import sustainfull from '../../Component Library/fullsust.png';
import sustainfully from '../../Component Library/image-sustain.jpg';
import { NavBar1 } from '../Components/navBar1';
import { useEffect } from 'react';
import MobileNavbar from '../Components/navbarMobile';
import SustainMobileNavbar from '../Components/sustainNav';
export const SustainabilityComponent: React.FC = () => {
  const backgroundStyle = {
    backgroundImage: `url(${sustainfull})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    width: '100%'
  };
  const backgroundStyle1 = {
    backgroundImage: `url(${sustainfully})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '519px',
  };
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <div>
    <div className='desktop hidden xl:block'>
    <div className='flex flex-col gap-[25px] w-screen h-screen' style={backgroundStyle} >
     <NavBar1/>
      <div className='w-full h-full flex flex-col'>
        <div className='w-full h-[30%] flex'></div>
        <div className='w-full h-[50%] flex-col'>
          <div className='w-full h-[10%] flex items-center justify-center text-[95px] font-bold text-[#0D712C] font-poppins tracking-custom'> <span className="gradient-text">Sustainability</span></div>
          
          <div className='w-full h-[10%] flex items-center justify-center text-[48px] font-medium text-[#848484] my-[40px] font-poppins'>in Every Step</div>
          
        </div>
        <div className='w-full h-[20%] flex '></div>
      </div>
    </div>
    </div>

    <div className='mobile xl:hidden sm:block h-[519px]' style={backgroundStyle1}>
      <div className='bg-white '>
      <SustainMobileNavbar/>
      </div>
      <div className='w-full h-full flex flex-col  mt-[-22px]' >
        <div className='w-full h-[30%] flex'></div>
        <div className='w-full h-[50%] flex-col'>
          <div className='w-full h-[10%] flex items-center justify-center text-[30px] font-bold text-[#0D712C] font-poppins tracking-customs'> <span className="gradient-text4">Sustainability</span></div>
          
          <div className='w-full h-[10%] flex items-center justify-center text-[18px] font-medium text-[#848484] my-[40px] font-poppins mt-[4px]'>in Every Step</div>
          
        </div>
        <div className='w-full h-[20%] flex '></div>
      </div>
    
       
    </div>
    </div>

  )
}



